<template>
    <div class="inner-environment">
        <common-title-panel title="실내 환경 요소 비교"></common-title-panel>
        <div class="gauges">
            <div class="environmentItem">
                <GaugeChart ref="tempGauge" :data="tempGaugeData" />
                <div class="descriptionText">겨울(20°C~24°C 권장), 여름(23°C~26°C 권장)</div>
                <inner-enviroment-comp-table :data="data.chartData.indoorTemp"></inner-enviroment-comp-table>
            </div>
            <div class="environmentItem">
                <GaugeChart ref="humiGauge" :data="humiGaugeData" />
                <div class="descriptionText">상대 습도(40%~60% 권장)</div>
                <inner-enviroment-comp-table :data="data.chartData.indoorHumi"></inner-enviroment-comp-table>
            </div>
            <div class="environmentItem">
                <GaugeChart ref="co2Gauge" :data="co2GaugeData" />
                <div class="descriptionText">CO2 관리 기준 (150ppm 이하, 환경부)</div>
                <inner-enviroment-comp-table :data="data.chartData.CO2"></inner-enviroment-comp-table>
            </div>
            <div class="environmentItem">
                <GaugeChart ref="pmGauge" :data="pmGaugeData" />
                <div class="descriptionText">초미세먼지 관리 기준(40㎍/㎥ 이하, 환경부)</div>
                <inner-enviroment-comp-table :data="data.chartData['PM2.5']"></inner-enviroment-comp-table>
            </div>
        </div>
    </div>
</template>

<script>
import GaugeChart from "../charts/GaugeChart.vue";
import InnerEnviromentCompTable from "../tables/InnerEnviromentCompTable.vue";
import CommonTitlePanel from "./CommonTitlePanel.vue";
import gaugeChart from "./gaugeChart";

export default {
    components: {
        GaugeChart,
        InnerEnviromentCompTable,
        CommonTitlePanel,
    },
    props: ["data"],
    computed: {
        tempGaugeData() {
            return {
                series: [
                    gaugeChart.createGaugeSeries({
                        min: 0,
                        max: 50,
                        splitNumber: 5,
                        axisLineColor: [
                            [0.32, "#ECEFF1"],
                            [0.48, "#33CC33"],
                            [0.64, "#FFCC00"],
                            [0.8, "#FF9900"],
                            [1.0, "#FF0000"],
                        ],
                        titleText: "Temperature (°C)",
                        detailFormatter: "{value} °C",
                        data: [{ value: this.data.chartData.indoorTemp.currentMonth.AVG, name: "온도" }],
                    }),
                ],
            };
        },
        humiGaugeData() {
            return {
                series: [
                    gaugeChart.createGaugeSeries({
                        min: 0,
                        max: 100,
                        splitNumber: 5,
                        axisLineColor: [
                            [0.2, "#66CCFF"],
                            [0.4, "#33CC33"],
                            [0.6, "#FFCC00"],
                            [0.8, "#FF9900"],
                            [1.0, "#FF0000"],
                        ],
                        titleText: "Humidity (%)",
                        detailFormatter: "{value} %",
                        data: [{ value: this.data.chartData.indoorHumi.currentMonth.AVG, name: "습도" }],
                    }),
                ],
            };
        },
        co2GaugeData() {
            return {
                series: [
                    gaugeChart.createGaugeSeries({
                        min: 0,
                        max: 1000,
                        splitNumber: 10,
                        axisLineColor: [[1, "#66CCFF"]],
                        titleText: "CO2 (ppm)",
                        detailFormatter: "{value} ppm",
                        data: [{ value: this.data.chartData.CO2.currentMonth.AVG, name: "CO2" }],
                    }),
                    gaugeChart.createGaugeSeries({
                        center: ["50%", "80%"],
                        min: 0,
                        max: 1000,
                        splitNumber: 10,
                        pointerOpacity: 0.8,
                        axisLineColor: [[1, "transparent"]],
                        pointerOffsetCenter: [0, "-60%"],
                        pointerColor: "#E8EAEE",
                        axisTick: { show: false },
                        splitLine: { show: false },
                        axisLabel: { show: false },
                        title: { show: false },
                        detail: { show: false },
                        data: [{ value: this.data.chartData.CO2.prevMonth.AVG, name: "CO2-2" }],
                    }),
                ],
            };
        },
        pmGaugeData() {
            return {
                series: [
                    gaugeChart.createGaugeSeries({
                        min: 0,
                        max: 100,
                        splitNumber: 10,
                        axisLineColor: [[1, "#66CCFF"]],
                        titleText: "PM2.5 (µg/m³)",
                        detailFormatter: "{value} µg/m³",
                        data: [{ value: this.data.chartData["PM2.5"].currentMonth.AVG, name: "PM2.5" }],
                    }),
                    gaugeChart.createGaugeSeries({
                        center: ["50%", "80%"],

                        min: 0,
                        max: 100,
                        splitNumber: 10,
                        axisLineColor: [[1, "transparent"]],
                        pointerOffsetCenter: [0, "-60%"],
                        pointerOpacity: 0.8,
                        pointerColor: "#E8EAEE",
                        axisTick: { show: false },
                        splitLine: { show: false },
                        axisLabel: { show: false },
                        title: { show: false },
                        detail: { show: false },
                        data: [{ value: this.data.chartData["PM2.5"].prevMonth.AVG, name: "PM2.5-2" }],
                    }),
                ],
            };
        },
    },
    methods: {
        resizeGaugeCharts() {
            this.$refs.tempGauge.resizeChart();
            this.$refs.humiGauge.resizeChart();
            this.$refs.co2Gauge.resizeChart();
            this.$refs.pmGauge.resizeChart();
        },
    },
};
</script>

<style scoped>
.gauges {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-around;
}

.environmentItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 25%;
    padding: 0 20px;
    height: 250px;
}

.descriptionText {
    text-align: center;
    margin-bottom: 10px;
}
</style>
