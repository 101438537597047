export default {
    createGaugeSeries(opts) {
        return {
            type: "gauge",
            startAngle: opts.startAngle || 180,
            endAngle: opts.endAngle || 0,
            center: opts.center || ["50%", "80%"],
            radius: opts.radius || "120%",
            min: opts.min,
            max: opts.max,
            splitNumber: opts.splitNumber,
            axisLine: {
                lineStyle: {
                    width: opts.axisLineWidth || 8,
                    color: opts.axisLineColor,
                },
            },
            pointer: {
                icon: opts.pointerIcon || "path://M12.8,0.7l12,40.1H0.7L12.8,0.7z",
                length: opts.pointerLength || "30%",
                width: opts.pointerWidth || 12,
                offsetCenter: opts.pointerOffsetCenter || [0, "-60%"],
                itemStyle: {
                    color: opts.pointerColor || "#FF0000",
                    // pointerOpacity를 옵션으로 받아 투명도를 설정합니다.
                    opacity: opts.pointerOpacity !== undefined ? opts.pointerOpacity : 1,
                },
            },
            axisTick: {
                length: opts.axisTickLength || 4,
                distance: opts.axisTickDistance || 1,
                lineStyle: {
                    color: opts.axisTickColor || "#90A4AE",
                    width: opts.axisTickWidth || 1,
                },
            },
            splitLine: {
                length: opts.splitLineLength || 8,
                distance: opts.splitLineDistance || 1,
                lineStyle: {
                    color: opts.splitLineColor || "#90A4AE",
                    width: opts.splitLineWidth || 2,
                },
            },
            axisLabel: {
                color: opts.axisLabelColor || "#464646",
                fontSize: opts.axisLabelFontSize || 10,
                distance: opts.axisLabelDistance || -20,
                rotate: opts.axisLabelRotate || "tangential",
            },
            title: Object.assign(
                {
                    offsetCenter: [0, "-10%"],
                    fontSize: 14,
                    text: opts.titleText || "",
                    show: true,
                },
                opts.title || {}
            ),
            detail: Object.assign(
                {
                    fontSize: opts.detailFontSize || 16,
                    offsetCenter: opts.detailOffsetCenter || [0, "-40%"],
                    valueAnimation: opts.valueAnimation !== undefined ? opts.valueAnimation : true,
                    color: opts.detailColor || "black",
                    formatter: opts.detailFormatter,
                    show: true,
                },
                opts.detail || {}
            ),
            data: opts.data,
        };
    },
};
