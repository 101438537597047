<template>
    <div v-if="costData" class="panel-wrapper">
        <div class="content-row shadow-box">
            <div class="col-3">
                <div class="stack-table-container">
                    <StackChart
                        ref="unitStackChart"
                        :title="'용도별 원단위 구성'"
                        :chartHeight="17"
                        :data="costData.values.unit.chartData"
                        :color="analyzeConfig.colorSet"
                        :xAxisLabel="xAxisLabelMonth"
                    />
                    <div style="margin-top: 10px;">
                        <CompTable :tableData="costData.values.unit.tableData" />
                    </div>
                </div>
            </div>
            <div class="col-3">
                <div class="stack-table-container">
                    <BarChart
                        ref="elecBarChart"
                        :title="'전기 사용량 원단위'"
                        :chartHeight="17"
                        :data="elecData.chart"
                        :color="analyzeConfig.colorSet"
                        :xAxisLabel="xAxisLabelMonth"
                    />
                    <div style="margin-top: 10px;">
                        <CompTable :tableData="elecData.table" />
                    </div>
                </div>
            </div>
            <div class="col-3">
                <div class="stack-table-container">
                    <BarChart
                        ref="gasBarChart"
                        :title="'가스 사용량 원단위'"
                        :chartHeight="17"
                        :data="gasData.chart"
                        :color="analyzeConfig.colorSet"
                        :xAxisLabel="xAxisLabelMonth"
                    />
                    <div style="margin-top: 10px;">
                        <CompTable :tableData="gasData.table" />
                    </div>
                </div>
            </div>
            <div class="col-3">
                <div class="stack-table-container">
                    <BarChart
                        ref="heatBarChart"
                        :title="'열 사용량 원단위'"
                        :chartHeight="17"
                        :data="heatData.chart"
                        :color="analyzeConfig.colorSet"
                        :xAxisLabel="xAxisLabelMonth"
                    />
                    <div style="margin-top: 10px;">
                        <CompTable :tableData="heatData.table" />
                    </div>
                </div>
            </div>
        </div>
        <div class="content-row shadow-box">
            <div class="col-3">
                <!-- <h5>{{ "EnergySource section4" }}</h5> -->
                <energy-overview-panel :data="overviewData"></energy-overview-panel>
            </div>
            <div class="col-3">
                <div class="stack-table-container">
                    <StackChart
                        ref="chargeStackChart"
                        :title="'용도별 에너지 비용 구성'"
                        :chartHeight="26"
                        :data="costData.values.ren.chartData"
                        :color="analyzeConfig.colorSet"
                        :xAxisLabel="xAxisLabelMonth"
                    />
                </div>
            </div>
            <div class="col-6">
                <CostAnalysisTable :costDableData="costDableData" />
            </div>
        </div>
    </div>
</template>

<script>
import BarChart from "../charts/BarChart";
import StackChart from "../charts/StackChart";
import CompTable from "../tables/CompTable";
import CostAnalysisTable from "../tables/CostAnalysisTable";
import analyzeConfig from "../analyzeConfig.json";
import EnergyOverviewPanel from "./EnergyOverviewPanel.vue";

export default {
    props: {
        costData: {
            type: Object,
            default: () => {},
        },
    },
    components: {
        BarChart,
        StackChart,
        CompTable,
        CostAnalysisTable,
        EnergyOverviewPanel,
    },
    data() {
        return {
            analyzeConfig: null,
            overviewData: null,
            xAxisLabelMonth: ["전월", "당월", "전년동월"],
        };
    },
    computed: {
        elecData() {
            const data = {
                chart: null,
                table: null,
            };
            data.chart = {
                전기: this.costData.values.src.chartData["전기"],
            };
            data.table = this.costData.values.src.tableData["전기"];
            return data;
        },
        gasData() {
            const data = {
                chart: null,
                table: null,
            };
            data.chart = {
                가스: this.costData.values.src.chartData["가스"],
            };
            data.table = this.costData.values.src.tableData["가스"];
            return data;
        },
        heatData() {
            const data = {
                chart: null,
                table: null,
            };
            data.chart = {
                지열: this.costData.values.src.chartData["지열"],
            };
            data.table = this.costData.values.src.tableData["지열"];
            return data;
        },
        costDableData() {
            const filterData = {
                usageData: {},
                energySourceData: {},
            };
            Object.keys(this.costData.values.trd.tableData).forEach((key) => {
                if (this.analyzeConfig.usageKey.includes(key)) {
                    filterData.usageData[key] = this.costData.values.trd.tableData[key];
                } else {
                    filterData.energySourceData[key] = this.costData.values.trd.tableData[key];
                }
            });
            return filterData;
        },
    },
    watch: {
        costData: {
            deep: true,
            immediate: true,
            handler() {
                this.overviewData = this.readyOverviewData();
            },
        },
    },
    mounted() {},
    created() {
        this.analyzeConfig = analyzeConfig;
    },
    methods: {
        readyOverviewData() {
            if (!this.costData) return;

            function toThousands(value) {
                // value가 숫자이면 1000으로 나누어 반환 (toLocaleString()을 이용해 천단위 구분 기호 추가)
                return typeof value === "number" && !isNaN(value) ? value / 1000 : 0;
            }

            // costData의 값들을 Number로 변환 후 천원 단위로 변환
            const costBenefit = toThousands(Number(this.costData.values.over.genBenefit[1]) ?? 0);
            const currCost = toThousands(Number(this.costData.values.over.elecCost[1]) ?? 0);
            const accCost = toThousands(Number(this.costData.values.over.ytdCost) ?? 0);

            const prevMonth = toThousands(Number(this.costData.values.over.elecCost[0]) ?? 0);
            const prevYear = toThousands(Number(this.costData.values.over.elecCost[2]) ?? 0);

            // 차이 계산 시에는 원래 숫자 값 대신 천원 단위의 값 사용
            const prevRate = prevMonth ? (((currCost - prevMonth) / prevMonth) * 100).toFixed(2) : 0;
            const prevYearRate = prevYear ? (((currCost - prevYear) / prevYear) * 100).toFixed(2) : 0;

            const maxValue = Math.max(currCost, prevMonth, prevYear);

            return {
                title: "에너지 비용 개요",
                color: "#448AFF",
                table: [
                    { "발전 비용편익": [Number(costBenefit.toFixed(0)), "천원"] },
                    { 비용: [Number(currCost.toFixed(0)), "천원"] },
                    { "당해년도 누적 비용": [Number(accCost.toFixed(0)), "천원"] },
                ],
                chart: {
                    max: maxValue,
                    prevMonth: {
                        per: prevRate,
                        value: [Number(prevMonth.toFixed(0)), "천원"],
                    },
                    now: { value: [Number(currCost.toFixed(0)), "천원"] },
                    prevYear: { per: prevYearRate, value: [Number(prevYear.toFixed(0)), "천원"] },
                },
            };
        },

        resizeChart() {
            this.$nextTick(() => {
                this.$refs.unitStackChart.resizeChart();
                this.$refs.elecBarChart.resizeChart();
                this.$refs.gasBarChart.resizeChart();
                this.$refs.heatBarChart.resizeChart();
                this.$refs.chargeStackChart.resizeChart();
            });
        },
    },
};
</script>

<style scoped>
.stack-table-container {
    width: 100%;
    height: 100%;
    /* display: flex;
    justify-content: space-between;
    flex-direction: column; */
}
.panel-wrapper {
    width: 100%;
    height: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px; /* row 간격 */
}

.content-row {
    display: flex;
    width: 100%;
    height: calc(50% - 5px); /* 두 영역 사이 gap 을 감안한 정확한 50% 분할 */
    padding: 10px;
    box-sizing: border-box;
    background-color: white;
    border-radius: 10px;
}

.shadow-box {
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.15);
}
</style>
