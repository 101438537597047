<template>
    <div style="width: 100%;">
        <table class="table table-bordered text-center align-middle">
            <colgroup>
                <col style="width:50%;" />
                <col style="width: 50%;" />
            </colgroup>
            <thead>
                <tr>
                    <th class="gray">{{ "서울시 온실가스총량제 기준" }}</th>
                    <th class="blue">{{ "당월" }}</th>
                </tr>
            </thead>
            <tbody>
                <tr style="font-weight: bold;">
                    <td>
                        {{ data.values.ghgEmission.baseline }}
                    </td>
                    <td>
                        {{ data.values.ghgEmission.buildingGHG }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    props: ["data"],
    components: {},
    data() {
        return {};
    },
    computed: {},
    watch: {},
    mounted() {},
    created() {},
    methods: {},
};
</script>

<style scoped>
/* table  */
.gray {
    background-color: #444;
    color: #fff;
}
.blue {
    background-color: #002060;
    color: #fff;
}

/* 증감률 값 표현할때 사용 예정 */
.increase {
    color: #ff4d4f;
}
.decrease {
    color: #4da6ff;
}
</style>
