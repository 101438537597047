<template>
    <div class="chart-container">
        <common-title-panel
            title="건물온실가스총량제"
            :subTitle="`단위면적당 탄소배출량 (${data.values.ghgEmission.unitInfo.unit} · 월)`"
        />
        <div ref="gaugeChart" class="chart" :style="{ height: chartHeight + 'vh' }"></div>
    </div>
</template>

<script>
import * as echarts from "echarts";
import CommonTitlePanel from "../panels/CommonTitlePanel.vue";

export default {
    props: ["data", "chartHeight"],
    components: { CommonTitlePanel },
    data() {
        return {
            chart: null,
        };
    },
    mounted() {
        this.renderChart();
    },
    watch: {
        data() {
            this.renderChart();
        },
    },
    methods: {
        resizeChart() {
            this.chart && this.chart.resize();
        },
        renderChart() {
            this.chart = echarts.init(this.$refs.gaugeChart);

            const ghg = this.data.values.ghgEmission;
            const tickValues = ghg.ticks;
            const minVal = tickValues[0];
            const maxVal = tickValues[tickValues.length - 1];
            const splitNum = tickValues.length - 1;
            const pointerValue = ghg.buildingGHG;

            const orangeColors = [
                "#FFE5E3",
                "#FFCBC8",
                "#FFB1AD",
                "#FF9792",
                "#FF7D77",
                "#FF635C",
                "#FF4941",
                "#FF493B",
                "#FF2F26",
            ];
            const colorSteps = orangeColors.map((color, idx) => {
                return [(idx + 1) / orangeColors.length, color];
            });

            const option = {
                tooltip: {
                    formatter: "{a} <br/>{b} : {c}",
                },
                grid: {
                    top: 10,
                },
                series: [
                    {
                        name: "Score",
                        type: "gauge",
                        startAngle: 180,
                        endAngle: 0,
                        min: minVal,
                        max: maxVal,
                        splitNumber: splitNum,
                        radius: "120%",
                        center: ["50%", "80%"],
                        progress: {
                            show: false,
                        },
                        tooltip: {
                            show: false,
                        },
                        axisLine: {
                            lineStyle: {
                                width: 70,
                                color: colorSteps,
                            },
                        },
                        pointer: {
                            show: true,
                            length: "100%",
                            width: 6,
                            itemStyle: {
                                color: "gray",
                            },
                        },
                        axisTick: {
                            show: true,
                            splitNumber: 1,
                            lineStyle: {
                                width: 2,
                                color: "#fff",
                            },
                        },
                        splitLine: {
                            show: true,
                            length: 10,
                            lineStyle: {
                                width: 3,
                                color: "#fff",
                            },
                        },
                        axisLabel: {
                            show: true,
                            distance: -40,
                            formatter: function(value) {
                                return value;
                            },
                            color: "#000",
                            fontSize: 12,
                        },
                        detail: {
                            show: false,
                        },
                        data: [
                            {
                                value: pointerValue,
                            },
                        ],
                    },
                ],
            };

            this.chart.setOption(option);
            window.addEventListener("resize", () => this.chart.resize());
        },
    },
};
</script>

<style scoped>
.chart-container {
}
.chart {
    width: 100%;
    /* height: 155px; */
}
</style>
