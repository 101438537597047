<template>
    <div v-if="consumpData && consumpData.values" class="panel-wrapper">
        <div class="content-row shadow-box">
            <div class="col-3">
                <energy-overview-panel :data="overviewDataFirst"></energy-overview-panel>
                <!-- <h5>{{ "consumption section1" }}</h5> -->
            </div>
            <div class="col-3">
                <div class="stack-table-container">
                    <StackChart
                        ref="energyConsumptionChart"
                        :title="'에너지원별 소비량 구성'"
                        :chartHeight="27"
                        :data="consumpData.values.src.chartData"
                        :color="analyzeConfig.colorSet"
                        :xAxisLabel="xAxisLabelMonth"
                    />
                </div>
            </div>
            <div class="col-3">
                <!-- <h5>{{ "consumption section3" }}</h5> -->
                <div class="stack-table-container">
                    <StackChart
                        ref="dayOfWeekChart"
                        :title="'요일별 에너지 소비 수준'"
                        :chartHeight="27"
                        :data="updateWeekEng"
                        :color="analyzeConfig.colorSet"
                        :xAxisLabel="consumpData.values.week.regDt"
                    />
                </div>
            </div>
            <div class="col-3">
                <!-- <h5>{{ "consumption section3" }}</h5> -->
                <BarChart
                    ref="timeEnergyChart"
                    :title="'시간대별 에너지 소비수준'"
                    :chartHeight="27"
                    :data="updateHourEng"
                    :color="analyzeConfig.colorSet"
                    :xAxisLabel="consumpData.values.hour.regDt"
                />
            </div>
        </div>
        <div class="content-row shadow-box">
            <div class="col-3">
                <!-- <h5>{{ "consumption section4" }}</h5> -->
                <energy-overview-panel :data="overviewDataSecond"></energy-overview-panel>
            </div>
            <div class="col-3">
                <div class="stack-table-container">
                    <StackChart
                        ref="energyRenewalChart"
                        :title="'신재생 에너지원별 발전량 구성'"
                        :chartHeight="27"
                        :data="consumpData.values.ren.chartData"
                        :color="analyzeConfig.colorSet"
                        :xAxisLabel="xAxisLabelMonth"
                    />
                </div>
            </div>
            <div class="col-6">
                <TrendChart
                    ref="energyRenewalSeveralChart"
                    :title="'신재생 에너지원별 발전량 추이'"
                    :chartHeight="27"
                    :data="updateTrendEng"
                    :color="analyzeConfig.colorSet"
                    :xAxisLabel="consumpData.values.trend.regDt"
                    :showLabel="false"
                />
            </div>
        </div>
    </div>
</template>

<script>
import EnergyOverviewPanel from "./EnergyOverviewPanel.vue";
import BarChart from "../charts/BarChart";
import TrendChart from "../charts/TrendChart";
import StackChart from "../charts/StackChart.vue";
import analyzeConfig from "../analyzeConfig.json";

export default {
    props: ["consumpData"],
    components: {
        BarChart,
        TrendChart,
        EnergyOverviewPanel,
        StackChart,
    },
    data() {
        return {
            dummyData: null,
            analyzeConfig: null,
            xAxisLabelMonth: ["전월", "당월", "전년동월"],

            overviewDataFirst: null,
            overviewDataSecond: null,
        };
    },
    computed: {
        updateWeekEng() {
            const chartData = this.consumpData.values.week.chartData;
            return Object.keys(chartData)
                .filter((key) => key !== "total")
                .reduce((acc, key) => {
                    acc[key] = chartData[key];
                    return acc;
                }, {});
        },
        updateHourEng() {
            return { 소비량: this.consumpData.values.hour.chartData.total };
        },
        updateTrendEng() {
            const chartData = this.consumpData.values.trend.chartData;

            const keys = Object.keys(chartData);

            const length = this.consumpData.values.trend.regDt.length || 0;

            const sumArray = Array.from({ length }, (_, idx) => {
                return keys.reduce((sum, key) => {
                    const value = chartData[key][idx];
                    return sum + (typeof value === "number" ? value : 0);
                }, 0);
            });

            chartData.total = sumArray;

            return chartData;
        },
    },
    watch: {
        consumpData: {
            immediate: true,
            deep: true,
            handler() {
                this.overviewDataFirst = this.readyOverviewData("consumption");
                this.overviewDataSecond = this.readyOverviewData("generate");
            },
        },
    },
    mounted() {},
    created() {
        this.analyzeConfig = analyzeConfig;
    },
    methods: {
        readyOverviewData(purpose) {
            if (!this.consumpData) return;
            let unit, title, color, table, chart;
            let currValue, prevMonth, prevYear, prevRate, prevYearRate, maxValue;

            if (purpose === "consumption") {
                unit = this.consumpData.headers.find((item) => item.key === "당월").unitSmallCode;
                const goalValue = this.consumpData.values.cons.goal ?? 0;
                currValue = this.consumpData.values.cons.currentMonth ?? 0;
                const savingRate = this.consumpData.values.cons.savingRate ?? 0;
                prevMonth = this.consumpData.values.cons.prevMonth ?? 0;
                prevYear = this.consumpData.values.cons.prevYearMonth ?? 0;

                prevRate = prevMonth ? (((currValue - prevMonth) / prevMonth) * 100).toFixed(2) : 0;
                prevYearRate = prevYear ? (((currValue - prevYear) / prevYear) * 100).toFixed(2) : 0;
                maxValue = Math.max(currValue, prevMonth, prevYear);

                title = "에너지 소비 개요";
                color = "#FF5252";
                table = [{ 목표량: [goalValue, unit] }, { 소비량: [currValue, unit] }, { 절감률: [savingRate, "%"] }];
                chart = {
                    max: maxValue,
                    prevMonth: { per: prevRate, value: [prevMonth, unit] },
                    now: { value: [currValue, unit] },
                    prevYear: { per: prevYearRate, value: [prevYear, unit] },
                };
            } else if (purpose === "generate") {
                unit = this.consumpData.headers.find((item) => item.key === "발전량").unitSmallCode;
                const consumpValue = this.consumpData.values.prod.currentMonthCons ?? 0;
                const currGenEngValue = this.consumpData.values.prod.currentMonthGen ?? 0;
                const currSelfEngRate = this.consumpData.values.prod.currentMonthSrf ?? 0;
                prevMonth = this.consumpData.values.prod.prevMonthGen ?? 0;
                prevYear = this.consumpData.values.cons.prevYearMonthGen ?? 0;

                prevRate = prevMonth ? (((currGenEngValue - prevMonth) / prevMonth) * 100).toFixed(2) : 0;
                prevYearRate = prevYear ? (((currGenEngValue - prevYear) / prevYear) * 100).toFixed(2) : 0;
                maxValue = Math.max(currGenEngValue, prevMonth, prevYear);

                title = "에너지 발전 개요";
                color = "#448AFF";
                table = [
                    { 소비량: [consumpValue, unit] },
                    { 발전량: [currGenEngValue, unit] },
                    { 자립률: [currSelfEngRate, "%"] },
                ];
                chart = {
                    prevMonth: { per: prevRate, value: [prevMonth, unit] },
                    max: maxValue,
                    now: { value: [currGenEngValue, unit] },
                    prevYear: { per: prevYearRate, value: [prevYear, unit] },
                };
            }

            return { title, color, table, chart };
        },
        resizeChart() {
            this.$nextTick(() => {
                this.$refs.energyConsumptionChart.resizeChart();
                this.$refs.energyRenewalChart.resizeChart();
                this.$refs.energyRenewalSeveralChart.resizeChart();
                this.$refs.dayOfWeekChart.resizeChart();
                this.$refs.timeEnergyChart.resizeChart();
            });
        },
    },
};
</script>

<style scoped>
.panel-wrapper {
    width: 100%;
    height: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px; /* row 간격 */
}

.content-row {
    display: flex;
    width: 100%;
    height: calc(50% - 5px); /* 두 영역 사이 gap 을 감안한 정확한 50% 분할 */
    padding: 10px;
    box-sizing: border-box;
    background-color: white;
    border-radius: 10px;
}

.shadow-box {
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.15);
}

.stack-table-container {
    width: 100%;
    height: 100%;
}
</style>
