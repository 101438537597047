<template>
    <div v-if="carbonData" class="panel-wrapper">
        <div class="content-row shadow-box">
            <!-- 상단 row -->
            <div class="col-3 fixHeight">
                <energy-efficiency-chart ref="evergyEffChart" :data="carbonData" />
            </div>
            <div class="col-3">
                <div class="stack-table-container">
                    <StackChart
                        ref="energyUsageChart"
                        :title="'단위면적당 용도별 1차 에너지 소비량'"
                        :subTitle="`(${carbonData.values.usage.unitInfo.unit} · 월)`"
                        :chartHeight="17"
                        :data="carbonData.values.usage.chartData"
                        :color="analyzeConfig.colorSet"
                        :xAxisLabel="xAxisLabelMonth"
                    />
                    <div style="margin-top: 10px;">
                        <CompTable :tableData="carbonData.values.usage.tableData" />
                    </div>
                </div>
            </div>
            <div class="col-3">
                <ZebGradeChart ref="ZebGradeChartRef" :data="carbonData" />
            </div>
            <div class="col-3">
                <div class="stack-table-container">
                    <StackChart
                        ref="selfRateChartRef"
                        :title="'신재생 에너지원별 자립률 구성'"
                        :chartHeight="19"
                        :data="carbonData.values.renewable.chartData"
                        :color="analyzeConfig.colorSet"
                        :xAxisLabel="xAxisLabelMonth"
                    />
                    <div style="margin-top: 10px;">
                        <CompTable :tableData="carbonData.values.renewable.tableData" />
                    </div>
                </div>
            </div>
        </div>
        <div class="content-row shadow-box">
            <!-- 하단 row -->
            <div class="col-3 fixHeight">
                <div class="emissionCap">
                    <BuildingEmissionCap ref="ze" :data="carbonData" :chartHeight="17" />
                    <div style="margin-top: 10px;">
                        <GreenhouseGasTable :data="carbonData" />
                    </div>
                </div>
            </div>
            <div class="col-3">
                <div class="stack-table-container">
                    <StackChart
                        ref="CO2UsageChartRef"
                        :title="'단위면적당 에너지원별 CO2 배출량'"
                        :subTitle="`(${carbonData.values.co2Emission.unitInfo.unit} · 월 )`"
                        :chartHeight="17"
                        :data="carbonData.values.co2Emission.chartData"
                        :color="analyzeConfig.colorSet"
                        :xAxisLabel="xAxisLabelMonth"
                    />
                    <div style="margin-top: 10px;">
                        <CompTable :tableData="carbonData.values.co2Emission.tableData" />
                    </div>
                </div>
            </div>
            <div class="col-6">
                <TrendChart
                    ref="CO2TrendChartRef"
                    :title="'에너지원별 CO2 배출량 추이'"
                    :subTitle="`(${carbonData.values.co2Trend.unit} vs ℃)`"
                    :chartHeight="25"
                    :data="carbonData.values.co2Trend.chartData"
                    :color="analyzeConfig.colorSet"
                    :xAxisLabel="carbonData.values.co2Trend.regDt"
                    :showLabel="false"
                />
            </div>
        </div>
    </div>
</template>

<script>
import StackChart from "../charts/StackChart";
import TrendChart from "../charts/TrendChart";
import CompTable from "../tables/CompTable";
import EnergyEfficiencyChart from "../charts/EnergyEfficiencyChart.vue";
import BuildingEmissionCap from "../charts/BuildingEmissionCapChart.vue";
import GreenhouseGasTable from "../tables/GreenhouseGasTable.vue";

import ZebGradeChart from "../charts/ZebGradeChart";
// 테스트 데이터, 삭제 예정
import analyzeConfig from "../analyzeConfig.json";

export default {
    props: {
        carbonData: {
            type: Object,
            default: () => {},
        },
    },
    components: {
        StackChart,
        TrendChart,
        CompTable,
        EnergyEfficiencyChart,
        BuildingEmissionCap,
        ZebGradeChart,
        GreenhouseGasTable,
    },
    data() {
        return {
            analyzeConfig: null,
            xAxisLabelMonth: ["전월", "당월", "전년동월"],
        };
    },
    computed: {},
    watch: {},
    mounted() {},
    beforeDestroy() {},
    created() {
        this.analyzeConfig = analyzeConfig;
    },
    methods: {
        resizeChart() {
            this.$refs.evergyEffChart.resizeChart();
            this.$refs.energyUsageChart.resizeChart();
            this.$refs.selfRateChartRef.resizeChart();
            this.$refs.ze.resizeChart();
            this.$refs.CO2UsageChartRef.resizeChart();
            this.$refs.CO2TrendChartRef.resizeChart();
            this.$refs.ZebGradeChartRef.resizeChart();
        },
    },
};
</script>

<style scoped>
.panel-wrapper {
    width: 100%;
    height: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px; /* row 간격 */
}

.content-row {
    display: flex;
    width: 100%;
    height: calc(50% - 5px); /* 두 영역 사이 gap 을 감안한 정확한 50% 분할 */
    padding: 10px;
    box-sizing: border-box;
    background-color: white;
    border-radius: 10px;
}

.shadow-box {
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.15);
}
.stack-table-container {
    width: 100%;
    height: 100%;
}

.fixHeight {
    max-height: 100%;
}
.emissionCap {
    width: 100%;
    height: 100%;
}
</style>
