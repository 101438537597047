<template>
    <div v-if="tableData" style="width: 100%;">
        <table class="table table-bordered text-center align-middle">
            <colgroup>
                <col style="width: 33%;" />
                <col style="width: 34%;" />
                <col style="width: 33%;" />
            </colgroup>
            <thead>
                <tr>
                    <th class="gray">{{ "전월 (증감)" }}</th>
                    <th class="blue">{{ "당월" }}</th>
                    <th class="gray">{{ "전년 동월 (증감)" }}</th>
                </tr>
            </thead>
            <tbody>
                <tr style="font-weight: bold;">
                    <td>
                        <span>{{ formattedValue.prevMonth }}</span>
                        <span
                            :class="
                                Number(formattedValue.prevDiff) > 0
                                    ? 'text-blue'
                                    : Number(formattedValue.prevDiff) < 0
                                    ? 'text-danger'
                                    : ''
                            "
                        >
                            {{ ` (${Number(formattedValue.prevDiff) > 0 ? "+" : ""}${formattedValue.prevDiff})` }}
                        </span>
                    </td>
                    <td>
                        <span>{{ formattedValue.currentMonth }}</span>
                    </td>
                    <td>
                        <span>{{ formattedValue.lastYearSameMonth }}</span>
                        <span
                            :class="
                                Number(formattedValue.lastYearDiff) > 0
                                    ? 'text-blue'
                                    : Number(formattedValue.lastYearDiff) < 0
                                    ? 'text-danger'
                                    : ''
                            "
                        >
                            {{
                                ` (${Number(formattedValue.lastYearDiff) > 0 ? "+" : ""}${formattedValue.lastYearDiff})`
                            }}
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    props: {
        tableData: {
            type: Object,
            default: () => {},
        },
    },
    components: {},
    data() {
        return {};
    },
    computed: {
        formattedValue() {
            const tableData = this.tableData;

            function _toStr(value) {
                return typeof value === "number" ? value.toFixed(1).toLocaleString() : value ?? 0;
            }

            function _calcDiff(current, previous) {
                if (current === null || previous === null) return "-";
                const numCurrent = Number(current);
                const numPrevious = Number(previous);
                // if (typeof current !== "number" || typeof previous !== "number") return 0;
                const diff = numCurrent - numPrevious;
                return diff.toFixed(1).toLocaleString();
            }

            const values = {
                prevMonth: _toStr(tableData.prevMonth),
                prevDiff: _calcDiff(tableData.currentMonth, tableData.prevMonth),
                currentMonth: _toStr(tableData.currentMonth),
                lastYearSameMonth: _toStr(tableData.prevYearMonth),
                lastYearDiff: _calcDiff(tableData.currentMonth, tableData.prevYearMonth),
            };

            return values;
        },
    },
    watch: {},
    mounted() {},
    created() {},
    methods: {},
};
</script>

<style scoped>
/* table  */
.gray {
    background-color: #444;
    color: #fff;
}
.blue {
    background-color: #003366;
    color: #fff;
}

/* 증감률 값 표현할때 사용 예정 */
.increase {
    color: #ff4d4f;
}
.decrease {
    color: #4da6ff;
}
</style>
