<template>
    <div style="width: 100%;">
        <common-title-panel :title="title" :subTitle="subTitle"></common-title-panel>
        <div ref="chart" style="width: 100%;" :style="{ height: chartHeight + 'vh' }"></div>
    </div>
</template>

<script>
/**
 * data, title 등을 props 로 받아 전월, 당월, 전년 동월을 표현하는 부분에 공통 stack 차트로 사용
 * TODO: 현재 title 은 props 로 받고 있고 data, option 등을 propos 로 받는 작업 해야함.
 */
import * as echarts from "echarts";
// import moment from "moment";
import CommonTitlePanel from "../panels/CommonTitlePanel.vue";

export default {
    props: {
        title: {
            type: String,
            default: "",
        },
        subTitle: {
            type: String,
            default: "",
        },
        chartHeight: {
            type: Number,
            default: 300,
        },
        data: {
            type: Object,
            default: () => {},
        },
        color: {
            type: Object,
            default: () => {},
        },
        xAxisLabel: {
            type: Array,
            default: () => [],
        },
        showLabel: {
            type: Boolean,
            default: true,
        },
    },
    components: { CommonTitlePanel },
    data() {
        return {
            chart: null,
            chartData: null,
        };
    },
    watch: {
        data: {
            async handler(newVal) {
                this.chartData = newVal;
                await this.initChart();
            },
        },
    },

    async mounted() {
        this.chartData = this.data;
        await this.initChart();
        this.$nextTick(() => {
            window.addEventListener("resize", this.resizeChart);
        });
    },
    methods: {
        initChart() {
            this.chart = echarts.init(this.$refs.chart);
            const title = this.title;
            const option = {
                tooltip: {
                    trigger: "axis",
                    axisPointer: { type: "shadow" },
                    formatter: (params) => {
                        let result = `<div style="width: 200px">
                                        <div class="mb-3">
                                            <p class="m-0 p-0">${params[0].axisValue}</p>`;
                        params.forEach((item) => {
                            let value;
                            if (title.includes("비용")) {
                                const divided = Math.floor(item.data / 1000);
                                value =
                                    divided >= 1000
                                        ? divided.toLocaleString()
                                        : divided == 0 || !divided
                                        ? "-"
                                        : divided;
                            } else {
                                value =
                                    item.data >= 1000
                                        ? item.data.toLocaleString()
                                        : item.data == 0 || !item.data
                                        ? "-"
                                        : item.data;
                            }
                            result += `<div style="display: flex; justify-content: space-between;">
                                            <div>
                                                ${item.marker}
                                                <span>${item.seriesName}</span>
                                            </div>
                                            <span style="font-weight: bold;">${value}</span>
                                        </div>`;
                        });
                        result += `</div></div>`;
                        return result;
                    },
                },
                legend: {
                    bottom: 0,
                    itemWidth: 15,
                    itemHeight: 10,
                    textStyle: {
                        fontSize: 12,
                    },
                },
                grid: {
                    top: 10,
                    left: "5%",

                    right: "5%",
                    bottom: 50,
                },
                xAxis: {
                    type: "category",
                    // data: this.xAxisLabel.map((item) => {
                    //     return moment(item).format("YYYY-MM") ?? "-";
                    // }),
                    data: this.xAxisLabel,
                },
                yAxis: {
                    type: "value",
                    axisLine: { show: false },
                    axisLabel: { show: false },
                    axisTick: { show: false },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: "#e0e0e0", // 그리드선 색상 지정
                            type: "dashed",
                        },
                    },
                },
            };
            // props 로 받은 data 세팅
            if (!this.isEmpty(this.chartData)) {
                option.series = [];
                option.legend.data = Object.keys(this.chartData);
                Object.keys(this.chartData).forEach((key) => {
                    option.series.push({
                        name: key,
                        type: "bar",
                        stack: "total",
                        data: this.chartData[key].map((item) => {
                            let data = item == 0 ? null : item;
                            return data;
                        }),
                        itemStyle: {
                            color: this.color[key],
                            // borderRadius: Object.keys(this.data).length - 1 == index ? [7, 7, 0, 0] : 0,
                        },
                        label: {
                            show: this.showLabel,
                            position: "inside",
                            fontSize: 12,
                            color: "#303030",
                            textBorderColor: "#fff",
                            textBorderWidth: 1,
                            hideOverlap: true,
                            formatter: (params) => {
                                const rawValue = params.data;

                                // null 이거나 undefined면 바로 라벨 숨김
                                if (rawValue == null) return "";

                                // valueForTooltip이 있는 객체일 수도 있고, 그냥 숫자일 수도 있음
                                const value =
                                    typeof rawValue === "object" && rawValue !== null
                                        ? rawValue.valueForTooltip ?? 0
                                        : rawValue;

                                // 안전하게 value가 숫자인지 확인
                                if (typeof value !== "number") return "";

                                // 현재 막대의 데이터 인덱스
                                const dataIndex = params.dataIndex;

                                // 전체 스택의 합 구하기
                                const stackValues = option.series.map((s) => {
                                    const d = s.data[dataIndex];
                                    if (d == null) return 0;
                                    if (typeof d === "object") return d.valueForTooltip ?? 0;
                                    return d;
                                });

                                const total = stackValues.reduce((sum, v) => sum + (v || 0), 0);
                                const ratio = total > 0 ? value / total : 0;

                                // 너무 작은 비중이면 라벨 숨기기
                                if (ratio < 0.1) return "";

                                if (title.includes("비용")) {
                                    const divided = Math.floor(value / 1000);
                                    return divided >= 1000 ? divided.toLocaleString() : divided;
                                } else {
                                    return value >= 1000 ? value.toLocaleString() : value;
                                }
                            },
                        },
                    });
                });
            }
            this.chart.setOption(option);
        },
        resizeChart() {
            if (this.chart) {
                this.chart.resize();
            }
        },
    },
};
</script>

<style scoped>
.chart-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
</style>
