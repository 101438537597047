<template>
    <div style="width: 100%;">
        <table class="table table-bordered text-center align-middle">
            <colgroup>
                <col style="width: 33%;" />
                <col style="width: 33%;" />
            </colgroup>
            <thead>
                <tr>
                    <th class="gray">{{ "전월 (증감)" }}</th>
                    <th class="gray">{{ "전년 동월 (증감)" }}</th>
                </tr>
            </thead>
            <tbody>
                <tr style="font-weight: bold;">
                    <td>
                        {{ data.prevMonth.AVG || "-" }}
                        <span class="text-danger">({{ calcDiff(data.currentMonth.AVG, data.prevMonth.AVG) }})</span>
                    </td>
                    <td>
                        {{ data.prevYearMonth.AVG || "-" }}
                        <span class="text-blue">({{ calcDiff(data.currentMonth.AVG, data.prevYearMonth.AVG) }})</span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    props: ["data"],
    components: {},
    data() {
        return {};
    },
    computed: {},
    watch: {},
    mounted() {},
    created() {},
    methods: {
        calcDiff(now, comp) {
            if (!this.isValidNumber(now) || !this.isValidNumber(comp)) return "-";
            const rate = now - comp;

            if (this.isValidNumber(rate)) {
                return rate.toFixed(1);
            } else {
                return "-";
            }
        },
        isValidNumber(num) {
            // null 또는 undefined인 경우 false 처리
            if (num === null || num === undefined) return false;
            // 숫자가 아니면 false 처리 (예: 문자열, 불린 등)
            if (typeof num !== "number") return false;
            // NaN, Infinity, -Infinity인 경우 false 처리
            if (!Number.isFinite(num)) return false;
            return true;
        },
    },
};
</script>

<style scoped>
/* table  */
.gray {
    background-color: #444;
    color: #fff;
}
.blue {
    background-color: #002060;
    color: #fff;
}

/* 증감률 값 표현할때 사용 예정 */
.increase {
    color: #ff4d4f;
}
.decrease {
    color: #4da6ff;
}
</style>
