<template>
    <div>
        <common-title-panel-vue :title="title" :subTitle="subTitle"></common-title-panel-vue>
        <div ref="chart" :style="{ width: '100%', height: chartHeight + 'vh' }"></div>
    </div>
</template>

<script>
import * as echarts from "echarts";
import CommonTitlePanelVue from "../panels/CommonTitlePanel.vue";

export default {
    props: {
        title: {
            type: String,
            default: "",
        },
        subTitle: {
            type: String,
            default: "",
        },
        chartHeight: {
            type: Number,
            default: 300,
        },
        data: {
            type: Array,
            default: () => [],
        },
        color: {
            type: Object,
            default: () => {},
        },
        xAxisLabel: {
            type: Array,
            default: () => [],
        },
        showLabel: {
            type: Boolean,
            default: true,
        },
        unit: {
            type: String,
            default: "-",
        },
        seriesKey: {
            type: String,
            default: "none",
        },
        seriesMax: {
            type: Number,
            default: 100,
        },
    },
    components: { CommonTitlePanelVue },
    data() {
        return {
            chart: null,
        };
    },
    watch: {
        data: {
            async handler(newVal) {
                this.chartData = newVal;
                await this.initChart();
            },
        },
    },
    async mounted() {
        this.chartData = this.data;
        await this.initChart();
        this.$nextTick(() => {
            window.addEventListener("resize", this.resizeChart);
        });
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.resizeChart);
    },
    methods: {
        getRoundedMaxValue(arr) {
            const maxValue = Math.max(...arr);
            const digits = Math.pow(10, Math.floor(Math.log10(maxValue)));
            return Math.ceil(maxValue / digits) * digits;
        },
        getRoundedMinValue(arr) {
            const minValue = Math.min(...arr);
            if (minValue === 0) return 0;

            const digits = Math.pow(10, Math.floor(Math.log10(Math.abs(minValue))));
            return Math.floor(minValue / digits) * digits;
        },
        initChart() {
            this.chart = echarts.init(this.$refs.chart);

            const option = {
                dataset: [
                    {
                        source: [this.chartData],
                    },
                    {
                        transform: {
                            type: "boxplot",
                            config: {
                                itemNameFormatter: (param) => this.xAxisLabel[param.value] ?? `항목 ${param.value}`,
                            },
                        },
                    },
                ],
                tooltip: {
                    trigger: "item",
                    axisPointer: {
                        type: "shadow",
                    },
                    formatter: (param) => {
                        const values = param.data;

                        const _valueFormat = (val) => {
                            return val % 1 === 0 ? val : val.toFixed(1);
                        };

                        return `
                            <div style='width: 200px'>
                                <div>
                                    ${param.marker}
                                    <span class='m-0 p-0'>${values[0]}</span>
                                </div>
                                <div style='display: flex; justify-content: space-between;'>
                                    <span>최대값</span>
                                    <span class='m-0 p-0' style="font-weight: bold;">${_valueFormat(values[5])}</span>
                                </div>
                                <div style='display: flex; justify-content: space-between;'>
                                    <span>Q3</span>
                                    <span class='m-0 p-0' style="font-weight: bold;">${_valueFormat(values[4])}</span>
                                </div>
                                <div style='display: flex; justify-content: space-between;'>
                                    <span>중간값</span>
                                    <span class='m-0 p-0' style="font-weight: bold;">${_valueFormat(values[3])}</span>
                                </div>
                                <div style='display: flex; justify-content: space-between;'>
                                    <span>Q1</span>
                                    <span class='m-0 p-0' style="font-weight: bold;">${_valueFormat(values[2])}</span>
                                </div>
                                <div style='display: flex; justify-content: space-between;'>
                                    <span>최소값</span>
                                    <span class='m-0 p-0' style="font-weight: bold;">${_valueFormat(values[1])}</span>
                                </div>
                            </div>
                        `;
                    },
                },
                grid: {
                    top: 15,
                    left: 30,
                    // right: "3%",
                    bottom: 80,
                },
                xAxis: {
                    type: "category",
                    data: this.xAxisLabel,
                },
                yAxis: {
                    type: "value",
                    // name: this.unit,
                    max: this.getRoundedMaxValue(this.chartData),
                    min: this.getRoundedMinValue(this.chartData),
                },
                series: [
                    {
                        // name: this.seriesKey,
                        type: "boxplot",
                        // data: [this.chartData],
                        datasetIndex: 1,
                        itemStyle: {
                            color: this.color[this.seriesKey] ?? "#1E90FF99",
                        },
                    },
                ],
            };

            this.chart.setOption(option);
        },
        resizeChart() {
            if (this.chart) {
                this.chart.resize();
            }
        },
    },
};
</script>

<style scoped></style>
