<template>
    <div class="zebContainer">
        <common-title-panel title="제로 에너지 건축물 등급"></common-title-panel>
        <div ref="chart" style="width: 100%; height: 100%;"></div>
    </div>
</template>

<script>
import * as echarts from "echarts";
import CommonTitlePanel from "../panels/CommonTitlePanel.vue";

export default {
    props: ["data"],
    components: { CommonTitlePanel },
    data() {
        return {
            chart: null,
        };
    },
    watch: {
        data() {
            this.initChart();
        },
    },
    mounted() {
        this.initChart();
        this.$nextTick(() => {
            window.addEventListener("resize", this.resizeChart);
        });
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.resizeChart);
    },
    methods: {
        resizeChart() {
            this.chart?.resize();
        },
        initChart() {
            this.chart = echarts.init(this.$refs.chart);

            const yLabels = ["ZEB Plus", "ZEB1", "ZEB2", "ZEB3", "ZEB4", "ZEB5"];
            const yLabels2 = ["", "", "1+++", "1++", "1+", "1"];
            const efficiencyValues = [0, 0, 10, 20, 40, 60]; // ZEB 등급별 값

            console.log("values ", this.data);
            const { measuredZEBGrade, certifiedZEBGrade } = this.data.values?.zebGrade;

            // y축 인덱스 매핑
            const nowYIndex = yLabels.indexOf(measuredZEBGrade);
            const beforeYIndex = yLabels.indexOf(certifiedZEBGrade);

            // x축 값 추출
            // const nowValue = selfRate;
            // const beforeValue = efficiencyValues[beforeYIndex];

            const option = {
                // tooltip: {
                //     trigger: "axis",
                //     axisPointer: { type: "shadow" },
                //     formatter: (params) => {
                //         return `${params[0].marker} ${params[0].seriesName}: ${params[0].axisValue} 등급`;
                //     },
                // },
                grid: [
                    { top: "3%", right: "50%", containLabel: true },
                    { top: "3%", left: "50%", containLabel: true },
                ],
                xAxis: [
                    {
                        type: "value",
                        inverse: false,
                        gridIndex: 0,
                        min: -100,
                        max: 0,
                        axisLabel: { show: false },
                        splitLine: { show: false },
                    },
                    {
                        type: "value",
                        gridIndex: 1,
                        min: 0,
                        max: 100,
                        axisLabel: { show: false },
                        splitLine: { show: false },
                    },
                ],
                yAxis: [
                    {
                        type: "category",
                        inverse: true,
                        data: yLabels,
                        gridIndex: 0,
                        axisLine: { show: false },
                        axisTick: { show: false },
                        axisLabel: { show: false },
                    },
                    {
                        type: "category",
                        inverse: true,
                        data: yLabels2,
                        gridIndex: 1,
                        axisLine: { show: false },
                        axisTick: { show: false },
                        axisLabel: { show: false },
                    },
                ],
                series: [
                    {
                        name: "제로 에너지 건축물 등급",
                        type: "bar",
                        xAxisIndex: 0,
                        yAxisIndex: 0,
                        data: [-100, -80, -60, -40, -20, -10],
                        barWidth: 34,
                        itemStyle: {
                            color: (params) => {
                                const colors = ["#005BBB", "#1986D1", "#3FAAE0", "#6FB9EB", "#A1D4F5", "#CCE9FB"];
                                return colors[params.dataIndex];
                            },
                        },
                        label: {
                            show: true,
                            position: "insideRight",
                            fontSize: 12,
                            color: "#303030",
                            textBorderColor: "#fff",
                            textBorderWidth: 2,
                            formatter: (params) => `${params.name}`,
                        },
                    },
                    {
                        name: "건축물 에너지 효율 등급",
                        type: "bar",
                        xAxisIndex: 1,
                        yAxisIndex: 1,
                        z: 10,
                        data: efficiencyValues,
                        barWidth: 34,
                        itemStyle: {
                            color: (params) => {
                                const colors = ["#88E097", "#6FD67C", "#4caf50", "#7cb342", "#cddc39", "#ffeb3b"];
                                return colors[params.dataIndex];
                            },
                        },
                        label: {
                            show: true,
                            position: "insideLeft",
                            fontSize: 12,
                            color: "#303030",
                            textBorderColor: "#fff",
                            textBorderWidth: 2,
                            formatter: (params) => `${params.name}`,
                        },
                        markLine: {
                            z: 30,
                            emphasis: {
                                disabled: true,
                            },
                            symbol: ["none", "none"],
                            label: { show: false },
                            lineStyle: {
                                type: "solid",
                                color: "#388E3C",
                            },
                            data: [
                                [
                                    {
                                        xAxis: 0,
                                        yAxis: nowYIndex,
                                        lineStyle: {
                                            color: "#388E3C",
                                            width: 1,
                                            type: "solid",
                                        },
                                    },
                                    {
                                        xAxis: 100,
                                        yAxis: nowYIndex,
                                        lineStyle: {
                                            color: "#388E3C",
                                            width: 1,
                                            type: "solid",
                                        },
                                    },
                                ],
                                [
                                    {
                                        xAxis: 0,
                                        yAxis: beforeYIndex,
                                        lineStyle: {
                                            color: "#1976D2",
                                            width: 1,
                                            type: "solid",
                                        },
                                    },
                                    {
                                        xAxis: 100,
                                        yAxis: beforeYIndex,
                                        lineStyle: {
                                            color: "#1976D2",
                                            width: 1,
                                            type: "solid",
                                        },
                                    },
                                ],
                            ],
                        },
                        markPoint: {
                            symbol: "path://M10,0 L90,0 L90,20 L10,20 L0,10 Z",
                            symbolSize: [55, 20],
                            emphasis: {
                                disabled: true,
                            },
                            data: [
                                {
                                    xAxis: 90,
                                    yAxis: nowYIndex,
                                    value: "측정등급",
                                    label: {
                                        show: true,
                                        position: "inside",
                                        formatter: "측정등급",
                                        color: "#fff",
                                        fontSize: 10,
                                        fontWeight: "bold",
                                    },
                                    itemStyle: {
                                        color: "#388E3C",
                                    },
                                },
                                {
                                    xAxis: 90,
                                    yAxis: beforeYIndex,
                                    value: "인증등급",
                                    label: {
                                        show: true,
                                        position: "inside",
                                        formatter: "인증등급",
                                        color: "#fff",
                                        fontSize: 10,
                                        fontWeight: "bold",
                                    },
                                    itemStyle: {
                                        color: "#1976D2",
                                    },
                                },
                            ],
                        },
                    },
                ],
            };

            this.chart.setOption(option);
        },
    },
};
</script>

<style scoped>
.zebContainer {
    width: 100%;
    height: 100%;
}
</style>
