<template>
    <div class="overviewContainer">
        <!-- <div class="overviewTitle">{{ data.title }}</div> -->
        <common-title-panel :title="data.title"></common-title-panel>
        <div class="overviewTable" :style="{ backgroundColor: data.color }">
            <div
                class="tableItem"
                v-for="(item, index) in data.table"
                :key="`table-item-${index}`"
                :class="{ tableCenter: index === 1 }"
            >
                <div class="tableItemTitle" v-for="(value, key) in item" :key="`title-${index}-${key}`">
                    {{ key }}
                </div>
                <div class="tableValue" v-for="(value, key) in item" :key="`value-${index}-${key}`">
                    <div class="tableValueNum">{{ value[0].toLocaleString() }}</div>
                    <div class="tableValueUnit">{{ value[1] }}</div>
                </div>
            </div>
        </div>
        <div class="overviewChart">
            <div class="charItem">
                <div class="overviewChartFont">전월<br />({{ data.chart.prevMonth.per }}%)</div>
                <div class="overviewProgress greyProgress">
                    <b-progress
                        ref="prevMonthProgress"
                        :height="progressHeight"
                        :value="animatedValues.prevMonth"
                        show-value
                        class="boxshadow progress-label-prev"
                        :max="data.chart.max"
                    />
                </div>
            </div>
            <div class="charItem">
                <div class="overviewChartFont">
                    당월 <br />
                    {{ Object.keys(data.table[1])[0] }}
                </div>
                <div class="overviewProgress customProgress">
                    <b-progress
                        ref="nowProgress"
                        :height="progressHeight"
                        :value="animatedValues.now"
                        show-value
                        class="boxshadow progress-label-now"
                        :style="{ '--progress-color': data.color }"
                        :max="data.chart.max"
                    />
                </div>
            </div>
            <div class="charItem">
                <div class="overviewChartFont">전년 동월<br />({{ data.chart.prevYear.per }}%)</div>
                <div class="overviewProgress greyProgress">
                    <b-progress
                        ref="prevYearProgress"
                        :height="progressHeight"
                        :value="animatedValues.prevYear"
                        show-value
                        class="boxshadow progress-label-prevyear"
                        :max="data.chart.max"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CommonTitlePanel from "./CommonTitlePanel.vue";
export default {
    props: ["data"],
    components: { CommonTitlePanel },
    data() {
        return {
            progressHeight: "40px",
            animatedValues: {
                prevMonth: 0,
                now: 0,
                prevYear: 0,
            },
        };
    },
    mounted() {
        this.makeUnitLabel();
        this.animateProgressBars();
    },
    watch: {
        data() {
            this.makeUnitLabel();
        },
    },
    methods: {
        animateProgressBars() {
            this.animatedValues.prevMonth = 0;
            this.animatedValues.now = 0;
            this.animatedValues.prevYear = 0;

            this.$nextTick(() => {
                setTimeout(() => {
                    this.animatedValues.prevMonth = this.data.chart.prevMonth.value[0] || 0;
                    this.animatedValues.now = this.data.chart.now.value[0] || 0;
                    this.animatedValues.prevYear = this.data.chart.prevYear.value[0] || 0;

                    this.makeUnitLabel();
                }, 500);
            });
        },
        makeUnitLabel() {
            this.$nextTick(() => {
                // 전월 progress-bar
                const prevMonthBar = this.$refs.prevMonthProgress.$el.querySelector(".progress-bar");
                if (prevMonthBar) {
                    const value = this.data.chart.prevMonth.value[0];
                    const unit = this.data.chart.prevMonth.value[1];
                    prevMonthBar.textContent = `${typeof value === "number" ? value.toLocaleString() : value} ${unit}`;
                }
                // 당월 progress-bar
                const nowBar = this.$refs.nowProgress.$el.querySelector(".progress-bar");
                if (nowBar) {
                    const value = this.data.chart.now.value[0];
                    const unit = this.data.chart.now.value[1];
                    nowBar.textContent = `${typeof value === "number" ? value.toLocaleString() : value} ${unit}`;
                }
                // 전년 동월 progress-bar
                const prevYearBar = this.$refs.prevYearProgress.$el.querySelector(".progress-bar");
                if (prevYearBar) {
                    const value = this.data.chart.prevYear.value[0];
                    const unit = this.data.chart.prevYear.value[1];
                    prevYearBar.textContent = `${typeof value === "number" ? value.toLocaleString() : value} ${unit}`;
                }
            });
        },
    },
};
</script>

<style scoped>
.overviewTitle {
    font-size: 18px;
    font-weight: 800;
    color: #4d4d4d;
    /* margin-bottom: 5px; */
    width: 100%;
    display: flex;
    justify-content: center;
}
.overviewContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    font-family: sans-serif;
}
.overviewTable {
    color: white;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    border-radius: 5px;
    padding: 5px 0;
}
.tableItem {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.tableItemTitle,
.tableValue {
    display: flex;
    justify-content: center;
}
.tableItemTitle {
    font-size: 14px;
}
.tableValue {
    display: flex;
    width: 100%;
}
.tableValueNum {
    font-size: 20px;
}
.tableValueUnit {
    font-size: 9px;
    height: 100%;
    display: flex;
    align-items: end;
    margin-left: 2px;
    padding-bottom: 4px;
}
.charItem {
    width: 100%;
    display: flex;
    margin: 1rem 0;
}
.overviewChartFont {
    width: 20%;
    text-align: center;
}
.overviewProgress {
    width: 80%;
}
.customProgress >>> .progress-bar {
    background-color: var(--progress-color);
}
.overviewChartFont {
    font-size: 13px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
}
.tableCenter {
    color: yellow;
}
.boxshadow {
    box-shadow: rgba(14, 63, 126, 0.04) 0px 0px 0px 1px, rgba(42, 51, 69, 0.04) 0px 1px 1px -0.5px,
        rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px, rgba(42, 51, 70, 0.04) 0px 6px 6px -3px,
        rgba(14, 63, 126, 0.04) 0px 12px 12px -6px, rgba(14, 63, 126, 0.04) 0px 24px 24px -12px;
}
</style>
