<template>
    <div class="titleContainer">
        <div class="common-title">{{ title }}</div>
        <small class="common-subtitle">{{ subTitle }}</small>
    </div>
</template>

<script>
export default {
    props: ["title", "subTitle"],
};
</script>

<style scoped>
.titleContainer {
    font-size: 18px;
    color: #4d4d4d;
    font-weight: 800;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.common-subtitle {
    font-size: 11px;
}
</style>
