<template>
    <div class="panel-wrapper">
        <div class="content-row shadow-box">
            <div class="col-3">
                <!-- 실내 환경 운영 개요 -->
                <inenr-enviroment-operate-overview
                    v-if="envData && envData.values && envData.values.envComparison"
                    :data="envData.values.envComparison"
                />
            </div>
            <div class="col-9">
                <!-- 실내 환경 요소 비교 -->
                <EnvironmentGaugeChartPanel
                    v-if="envData && envData.values && envData.values.envComparison"
                    ref="innerEnvironment"
                    :data="envData.values.envComparison"
                />
            </div>
        </div>
        <div class="content-row shadow-box">
            <div class="col-2">
                <BoxPlotChart
                    v-if="envData && envData.values && envData.values.envRange"
                    ref="tempBoxPlot"
                    :title="'실내 온도 운영 범위'"
                    :subTitle="`(℃)`"
                    :chartHeight="27"
                    :data="envData.values.envRange.chartData.indoorTemp"
                    :color="analyzeConfig.colorSet"
                    :xAxisLabel="['실내온도']"
                    :showLabel="false"
                    :unit="'℃'"
                    :seriesKey="'indoorTemp'"
                />
            </div>
            <div class="col-2">
                <BoxPlotChart
                    v-if="envData && envData.values && envData.values.envRange"
                    ref="humiBoxPlot"
                    :title="'외기 온도 변동 범위'"
                    :subTitle="`(℃)`"
                    :chartHeight="27"
                    :data="envData.values.envRange.chartData.outerTemp"
                    :color="analyzeConfig.colorSet"
                    :xAxisLabel="['외기온도']"
                    :showLabel="false"
                    :unit="'℃'"
                    :seriesKey="'outerTemp'"
                />
            </div>
            <div class="col-2">
                <BoxPlotChart
                    v-if="envData && envData.values && envData.values.envRange"
                    ref="co2BoxPlot"
                    :title="'실내 CO2 농도 운영 범위'"
                    :subTitle="`(ppm)`"
                    :chartHeight="27"
                    :data="envData.values.envRange.chartData.CO2"
                    :color="analyzeConfig.colorSet"
                    :xAxisLabel="['CO2']"
                    :showLabel="false"
                    :unit="'ppm'"
                    :seriesKey="'CO2'"
                />
            </div>
            <div class="col-6">
                <TrendChart
                    v-if="envData && envData.values && envData.values.consTempTrend"
                    ref="energyRenewalSeveralChart"
                    title="에너지 소비량 및 온도 추이"
                    :chartHeight="27"
                    :data="envData.values.consTempTrend.trend.chartData"
                    :color="analyzeConfig.colorSet"
                    :xAxisLabel="envData.values.consTempTrend.trend.regDt"
                    :showLabel="false"
                />
            </div>
        </div>
    </div>
</template>

<script>
import EnvironmentGaugeChartPanel from "../panels/EnvironmentGaugeChartPanel.vue";
import InenrEnviromentOperateOverview from "./InenrEnviromentOperateOverview.vue";
import BoxPlotChart from "../charts/BoxPlotChart";
import TrendChart from "../charts/TrendChart";
import analyzeConfig from "../analyzeConfig.json";

export default {
    props: ["envData"],
    components: {
        EnvironmentGaugeChartPanel,
        InenrEnviromentOperateOverview,
        BoxPlotChart,
        TrendChart,
    },
    data() {
        return {
            analyzeConfig, // analyzeConfig를 바로 data에 할당
        };
    },
    methods: {
        resizeChart() {
            this.$nextTick(() => {
                this.$refs.tempBoxPlot.resizeChart();
                this.$refs.humiBoxPlot.resizeChart();
                this.$refs.co2BoxPlot.resizeChart();
                this.$refs.energyRenewalSeveralChart.resizeChart();
                this.$refs.innerEnvironment.resizeGaugeCharts();
            });
        },
    },
};
</script>

<style scoped>
.panel-wrapper {
    width: 100%;
    height: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px; /* row 간격 */
}

.content-row {
    display: flex;
    width: 100%;
    height: calc(50% - 5px); /* 두 영역 사이 gap을 고려한 50% 분할 */
    padding: 10px;
    box-sizing: border-box;
    background-color: white;
    border-radius: 10px;
}

.shadow-box {
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.15);
}
</style>
