<template>
    <div :class="['smiley-face', { bouncing: mood === 'smile' }]">
        <div class="eye left"></div>
        <div class="eye right"></div>
        <div :class="['smile', mood]"></div>
    </div>
</template>

<script>
export default {
    name: "SmileyFace",
    props: {
        mood: {
            type: String,
            default: "smile",
        },
    },
};
</script>

<style scoped>
.smiley-face {
    width: 40px;
    height: 40px;
    background-color: transparent;
    border: 2px solid white;
    border-radius: 50%;
    position: relative;
    margin: auto;
}

.bouncing {
    animation: faceBounce 2s infinite ease-out;
}

@keyframes faceBounce {
    0% {
        transform: translateY(0) scaleX(1.1) scaleY(0.99);
    }
    20% {
        transform: translateY(-4px) scaleX(1) scaleY(1);
    }
    40% {
        transform: translateY(-8px) scaleX(1) scaleY(1);
    }
    50% {
        transform: translateY(-10px) scaleX(0.95) scaleY(1.05);
    }
    60% {
        transform: translateY(-8px) scaleX(1) scaleY(1);
    }
    80% {
        transform: translateY(-2px) scaleX(1) scaleY(1);
    }
    100% {
        transform: translateY(0) scaleX(1.1) scaleY(0.99);
    }
}

.eye {
    width: 4px;
    height: 4px;
    background-color: white;
    border-radius: 50%;
    position: absolute;
    top: 12px;
}
.eye.left {
    left: 10px;
}
.eye.right {
    right: 10px;
}

.smile {
    width: 24px;
    height: 12px;
    border-bottom: 2px solid white;
    border-radius: 0 0 10px 10px;
    position: absolute;
    bottom: 8px;
    left: 50%;
    transform: translateX(-50%);
}

/* sad 상태일 때 입 모양 */
.smile.sad {
    width: 13px;
    border-radius: 0;
}

/* angry 상태일 때 입 모양: 위쪽 테두리로 둥글게 표현 */
.smile.angry {
    width: 15px;
    height: 12px;
    border-top: 2px solid white;
    border-radius: 9px 9px 0 0;
    position: absolute;
    top: 22px;
    left: 50%;
    border-bottom: none;
}
</style>
