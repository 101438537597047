<template>
    <div v-if="buildingInfo">
        <div class="row" style="width: 100%;;">
            <div class="col-1">
                <div class="building-title-container">
                    <div class="building-title">{{ "건축물 개요" }}</div>
                    <!-- <span class="building-icon">
                        <i class="fa fa-building"></i>
                    </span> -->
                    <img src="@assets/default/icon/analysis_icon.png" width="45" />
                </div>
            </div>
            <div class="col-9">
                <table
                    class="table table-bordered text-center align-middle mb-0"
                    style="table-layout: fixed; width: 100%; font-size: 0.8rem;"
                >
                    <colgroup>
                        <col style="width: 10%;" />
                        <col style="width: 14%;" />
                        <col style="width: 15%;" />
                        <col style="width: 23%;" />
                        <col style="width: 15%;" />
                        <col style="width: 23%;" />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th class="text-white" scope="row">{{ "건축물 명" }}</th>
                            <td>{{ "한국녹색기후기술원" }}</td>
                            <th class="text-white" scope="row">{{ "용도" }}</th>
                            <td>{{ buildingInfo.usage ?? "-" }}</td>
                            <th class="text-white" scope="row">{{ "건물 정보" }}</th>
                            <td>
                                {{
                                    `지상 ${buildingInfo.grdFloor ?? "-"}층 / 
                                    지하 ${buildingInfo.urgFloor ?? "-"}층 / 
                                    연면적 ${buildingInfo.totalArea} ㎡`
                                }}
                            </td>
                        </tr>
                        <tr>
                            <th class="text-white" scope="row">{{ "준공일" }}</th>
                            <td>{{ buildingInfo.completionDate ?? "-" }}</td>
                            <th class="text-white" scope="row">{{ "제로에너지건축물 등급" }}</th>
                            <td>{{ buildingInfo.zebRating ?? "-" }} ({{ buildingInfo.zebDate ?? "-" }})</td>
                            <th class="text-white" scope="row">{{ "건축물에너지효율 등급" }}</th>
                            <td>{{ buildingInfo.beRating ?? "-" }} ({{ buildingInfo.beDate ?? "-" }})</td>
                        </tr>
                        <tr>
                            <th class="text-white" scope="row">{{ "주소" }}</th>
                            <td colspan="5">{{ buildingInfo.siteAddr ?? "-" }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-2">
                <!-- {{ "search button" }} -->
                <div style="display: flex; width: 100%; height: 50%;">
                    <div style="width: 50%; margin-right: 5px; height: 100%;" class="bldInfoYear">
                        <DatePicker
                            v-model="yearDate"
                            :type="'year'"
                            :format="'YYYY년'"
                            :valueType="'YYYY'"
                            :placeholder="'연 선택'"
                            lang="ko"
                            @change="selectYearDate"
                        />
                    </div>
                    <div style="width: 50%; margin-left: 5px; height: 100%;" class="bldInfoMonth">
                        <DatePicker
                            v-model="monthDate"
                            :type="'month'"
                            :format="'MM월'"
                            :valueType="'MM'"
                            :placeholder="'월 선택'"
                            lang="ko"
                            :append-to-body="false"
                            @change="runAnalysis(yearDate, monthDate)"
                        />
                    </div>
                    <!-- @click="prevDay" -->
                    <!-- <div style="width: 10%; height: 100%;">
                        <button class="date-icon" v-b-tooltip :title="'이전 월 조회'">
                            <i class="fas fa-angle-left" aria-hidden="true"></i>
                        </button>
                    </div>
                    <div style="width: 10%; height: 100%;">
                        <button class="date-icon" v-b-tooltip :title="'다음 월 조회'">
                            <i class="fas fa-angle-right" aria-hidden="true"></i>
                        </button>
                    </div> -->
                </div>
                <div style="display: flex; width: 100%; height: 50%;">
                    <div style="width: 50%; margin-right: 5px;">
                        <button type="button" class="btn btn-secondary" style="width: 100%;">{{ "보고서" }}</button>
                    </div>
                    <div style="width: 50%; margin-left: 5px;">
                        <button :disabled="activeTab === 3" type="button" class="btn btn-primary" style="width: 100%;">
                            {{ "데이터" }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import DatePicker from "vue2-datepicker";

export default {
    props: ["buildingInfo", "activeTab"],
    components: {
        DatePicker,
    },
    data() {
        return {
            yearDate: moment(new Date()).format("YYYY"),
            monthDate: moment(new Date()).format("MM"),
        };
    },
    computed: {},
    watch: {},
    mounted() {
        this.runAnalysis(this.yearDate, this.monthDate);
    },
    created() {},
    methods: {
        selectYearDate() {
            this.monthDate = null;
        },
        runAnalysis(year, month) {
            this.$emit("search", year, month);
        },
    },
};
</script>

<style scoped>
.row {
    margin: 0px;
}
.building-title-container {
    width: 100%;
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.building-title {
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 5px;
}
.building-icon {
    font-size: 2rem;
}
.table td,
.table th {
    padding: 2px;
    height: 28px;
    vertical-align: middle;
}

.table th {
    background-color: #003366;
}
.date-icon {
    color: #555;
    background-color: #fff;
    border: solid #bbb 1px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.2rem;
    transition: background-color 0.5s;
    /* width: 100%;
    height: auto; */
}

.date-icon:hover {
    background-color: #c7c7c7;
}

.date-icon.active {
    color: #fff;
    background-color: #555;
}
</style>
