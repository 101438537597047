var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.tableData)?_c('div',{staticStyle:{"width":"100%"}},[_c('table',{staticClass:"table table-bordered text-center align-middle"},[_vm._m(0),_c('thead',[_c('tr',[_c('th',{staticClass:"gray"},[_vm._v(_vm._s("전월 (증감)"))]),_c('th',{staticClass:"blue"},[_vm._v(_vm._s("당월"))]),_c('th',{staticClass:"gray"},[_vm._v(_vm._s("전년 동월 (증감)"))])])]),_c('tbody',[_c('tr',{staticStyle:{"font-weight":"bold"}},[_c('td',[_c('span',[_vm._v(_vm._s(_vm.formattedValue.prevMonth))]),_c('span',{class:Number(_vm.formattedValue.prevDiff) > 0
                                ? 'text-blue'
                                : Number(_vm.formattedValue.prevDiff) < 0
                                ? 'text-danger'
                                : ''},[_vm._v(" "+_vm._s(` (${Number(_vm.formattedValue.prevDiff) > 0 ? "+" : ""}${_vm.formattedValue.prevDiff})`)+" ")])]),_c('td',[_c('span',[_vm._v(_vm._s(_vm.formattedValue.currentMonth))])]),_c('td',[_c('span',[_vm._v(_vm._s(_vm.formattedValue.lastYearSameMonth))]),_c('span',{class:Number(_vm.formattedValue.lastYearDiff) > 0
                                ? 'text-blue'
                                : Number(_vm.formattedValue.lastYearDiff) < 0
                                ? 'text-danger'
                                : ''},[_vm._v(" "+_vm._s(` (${Number(_vm.formattedValue.lastYearDiff) > 0 ? "+" : ""}${_vm.formattedValue.lastYearDiff})`)+" ")])])])])])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('colgroup',[_c('col',{staticStyle:{"width":"33%"}}),_c('col',{staticStyle:{"width":"34%"}}),_c('col',{staticStyle:{"width":"33%"}})])
}]

export { render, staticRenderFns }