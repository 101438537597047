<template>
    <div style="width: 100%; height: 91vh; display: flex; flex-direction: column;">
        <!-- header -->
        <div class="analysis-header" style="flex: 0 0 auto;">{{ "건물 에너지 효율 분석" }}</div>
        <!-- building info -->
        <div class="building-info-panel" style="flex: 0 0 auto;">
            <BuildingInfoPanel
                ref="buildingInfoPanel"
                :buildingInfo="buildingInfo"
                @search="analysisReport"
                :activeTab="activeTab"
            />
        </div>
        <!-- tab area -->
        <div class="tab-container overflowStyle">
            <!-- tab button -->
            <ul class="custom-tabs">
                <li
                    class="custom-tab"
                    v-for="(tab, index) in tabs"
                    :key="index"
                    :class="{ active: activeTab === index }"
                    @click="handleTabClick(index)"
                >
                    {{ tab.label }}
                </li>
                <li
                    class="custom-tab auto-tab-button"
                    v-b-tooltip.hover
                    :title="`${autoTabActive ? '탭 자동 이동 중지' : '탭 자동 이동 시작'}`"
                    @click="toggleAutoTab"
                >
                    <i v-if="autoTabActive" class="fa fa-pause"></i>
                    <i v-else class="fa fa-play"></i>
                </li>
            </ul>
            <!-- tab content -->
            <!-- <div class="tab-content-container tab-content border p-3 mb-0">
                <div v-show="activeTab === 0" style="width: 100%; height: 100%;">
                    <CarbonAnalysisPanel :carbonData="carbonData" ref="energyCarbonPanel" />
                </div>
                <div v-show="activeTab === 1" style="width: 100%; height: 100%;">
                    <ConsumptionAnalysisPanel ref="consumptionAnalysisPanel" :consumpData="consumpData" />
                </div>
                <div v-show="activeTab === 2" style="width: 100%; height: 100%;">
                    <EnvironmentAnalysisPanel ref="environmentAnalysisPanel" :envData="envData" />
                </div>
                <div v-show="activeTab === 3" style="width: 100%; height: 100%;">
                    <EnergySouceAnalysisPanel ref="energySouceAnalysisPanel" :costData="costData" />
                </div>
            </div> -->
            <div class="tab-content-container tab-content border p-3 mb-0 overflowStyle">
                <transition :name="isAutoTabChanging ? 'slide-fade' : ''" mode="out-in">
                    <component
                        :is="tabs[activeTab].component"
                        v-bind="tabs[activeTab].props()"
                        :key="activeTab"
                        :ref="tabs[activeTab].ref"
                    />
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import backEndApi from "@src/api/backEndApi";
import BuildingInfoPanel from "./panels/BuildingInfoPanel.vue";
import CarbonAnalysisPanel from "./panels/CarbonAnalysisPanel.vue";
import ConsumptionAnalysisPanel from "./panels/ConsumptionAnalysisPanel.vue";
import EnvironmentAnalysisPanel from "./panels/EnvironmentAnalysisPanel.vue";
import EnergySouceAnalysisPanel from "./panels/EnergySouceAnalysisPanel.vue";

export default {
    components: {
        BuildingInfoPanel,
        CarbonAnalysisPanel,
        ConsumptionAnalysisPanel,
        EnvironmentAnalysisPanel,
        EnergySouceAnalysisPanel,
    },
    data() {
        return {
            /**
             * 건물 정보
             * @type {Object} buildingInfo - 건물 정보
             */
            buildingInfo: null,
            /**
             * 탭 관련 데이터
             * @type {number} activeTab - 활성화된 탭 인덱스
             * @type {Array} tabs - 탭 목록
             */
            activeTab: 0,
            autoTabActive: false,
            autoTabInterval: null,
            isAutoTabChanging: false, // 탭 전환이 자동인지 수동인지 여부
            tabs: [
                {
                    label: "에너지 및 탄소 분석",
                    component: CarbonAnalysisPanel,
                    props: () => ({ carbonData: this.carbonData }),
                    ref: "energyCarbonPanel",
                },
                {
                    label: "소비 및 생산 분석",
                    component: ConsumptionAnalysisPanel,
                    props: () => ({ consumpData: this.consumpData }),
                    ref: "consumptionAnalysisPanel",
                },
                {
                    label: "운영 환경 분석",
                    component: EnvironmentAnalysisPanel,
                    props: () => ({ envData: this.envData }),
                    ref: "environmentAnalysisPanel",
                },
                {
                    label: "원단위 및 비용 분석",
                    component: EnergySouceAnalysisPanel,
                    props: () => ({ costData: this.costData }),
                    ref: "energySouceAnalysisPanel",
                },
            ],
            // 에너지 및 탄소 분석 탭 데이터
            carbonData: null,
            // 소비 및 생산 분석 탭 데잍
            consumpData: null,
            // 운영 환경 분석 탭 데이터
            envData: null,
            // 원단위 및 비용 분석 탭 데이터
            costData: null,
            today: new Date(),
        };
    },
    computed: {
        // returnTrendData() {
        //     if (this.activeTab === 0) {
        //         return this.carbonData.values.co2Trend;
        //     } else if (this.activeTab === 1) {
        //         return this.consumpData.values.trend;
        //     } else if (this.activeTab === 2) {
        //         return this.costData.values.consTempTrend;
        //     } else {
        //     }
        // },
    },
    watch: {
        // activeTab(newVal) {
        //     this.$nextTick(() => {
        //         if (newVal === 3) {
        //             this.$refs.energySouceAnalysisPanel.resizeChart();
        //         } else if (newVal === 1) {
        //             this.$refs.consumptionAnalysisPanel.resizeChart();
        //         } else if (newVal === 2) {
        //             this.$refs.environmentAnalysisPanel.resizeChart();
        //         } else if (newVal === 0) {
        //             this.$refs.energyCarbonPanel.resizeChart();
        //         }
        //     });
        // },
    },
    mounted() {},
    beforeDestroy() {
        // 컴포넌트가 파괴되기 전에 interval 제거
        if (this.autoTabInterval) {
            clearInterval(this.autoTabInterval);
        }
    },
    async created() {
        // 건축물 정보
        await this.getSiteInfo();
    },
    methods: {
        toggleAutoTab() {
            if (this.autoTabActive) {
                clearInterval(this.autoTabInterval);
                this.autoTabInterval = null;
            } else {
                this.autoTabInterval = setInterval(() => {
                    this.isAutoTabChanging = true;
                    this.activeTab = (this.activeTab + 1) % this.tabs.length;
                    setTimeout(() => {
                        this.isAutoTabChanging = false;
                    }, 500);
                }, 10000);
            }
            this.autoTabActive = !this.autoTabActive;
        },
        handleTabClick(index) {
            this.isAutoTabChanging = false;
            this.activeTab = index;
        },
        analysisReport(year, month) {
            const regDt = year + month ?? moment(new Date()).format("YYYY-MM");
            // 에너지 및 탄소 분석
            this.analyzeEnergyAndCarbon(regDt);
            // 소비 및 생산 분석
            this.analyzeEnergyFlow(regDt);
            // 운영 환경 분석 추가 예정
            this.analyzeOperatingEnvironment(regDt);
            // 원단위 및 비용 분석
            this.analyzeEnergyUnitAndCost(regDt);
        },
        async getSiteInfo() {
            try {
                let result = await backEndApi.buildingEfficiency.getSiteInfo();
                if (result.status == 200) {
                    this.buildingInfo = result.data;
                } else {
                    this.alertWarning(`${result.data.message}`);
                }
            } catch (e) {
                console.error(e);
            }
        },
        async analyzeEnergyAndCarbon(regDt) {
            // this.carbonData = null;
            try {
                let result = await backEndApi.buildingEfficiency.analyzeEnergyAndCarbon(regDt);
                if (result.status == 200) {
                    this.carbonData = result.data;
                } else {
                    this.alertWarning(`${result.data.message}`);
                }
            } catch (e) {
                console.error(e);
            }
        },
        async analyzeEnergyFlow(regDt) {
            // this.consumpData = null;
            try {
                let result = await backEndApi.buildingEfficiency.analyzeEnergyFlow(regDt);
                if (result.status == 200) {
                    this.consumpData = result.data;
                } else {
                    this.alertWarning(`${result.data.message}`);
                }
            } catch (e) {
                console.error(e);
            }
        },
        async analyzeOperatingEnvironment(regDt) {
            try {
                let result = await backEndApi.buildingEfficiency.analyzeOperatingEnvironment(regDt);
                if (result.status == 200) {
                    this.envData = result.data;
                } else {
                    this.alertWarning(`${result.data.message}`);
                }
            } catch (e) {
                console.error(e);
            }
        },
        async analyzeEnergyUnitAndCost(regDt) {
            // this.costData = null;
            try {
                let result = await backEndApi.buildingEfficiency.analyzeEnergyUnitAndCost(regDt);
                if (result.status == 200) {
                    this.costData = result.data;
                } else {
                    this.alertWarning(`${result.data.message}`);
                }
            } catch (e) {
                console.error(e);
            }
        },
    },
};
</script>

<style scoped>
/* 공통 */
.row {
    margin: 0px;
}

/* 페이지 해더더 */
.analysis-header {
    background-color: #002060;
    color: white;
    font-size: 1rem;
    padding: 5px 10px;
    font-weight: bold;
    width: fit-content;
    height: fit-content;
    border-top-right-radius: 5px;
}

/* 건축물 정보 패널널 */
.building-info-panel {
    width: 100%;
    height: auto;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    margin: 0px 0px 10px 0px !important;
    background-color: white;
}

/* 탭 커스텀 스타일 */
.custom-tabs {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
}
.custom-tab {
    padding: 0.5rem 1.2rem;
    cursor: pointer;
    transition: all 0.2s ease;
    margin-right: 0.5rem;
    border-radius: 8px 8px 0 0;
    background-color: #f8f9fa;
    color: #495057;
    font-weight: 700;
    font-size: 13px;
}
.custom-tab:hover {
    background-color: #e2e6ea;
}
.custom-tab.active {
    background-color: #348fe2;
    color: white;
}
.auto-tab-button {
    margin-left: auto;
    background-color: #6c757d;
    color: white;
}

.auto-tab-button:hover {
    background-color: #5a6268;
}
.tab-container {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
.tab-content-container {
    flex: 1 1 auto;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #fff;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    position: relative;
}
.slide-fade-enter-active,
.slide-fade-leave-active {
    transition: all 0.5s ease;
    position: absolute;
    width: 100%;
}

.slide-fade-enter {
    transform: translateX(100%);
    opacity: 0;
}

.slide-fade-leave-to {
    transform: translateX(-100%);
    opacity: 0;
}
.overflowStyle {
    overflow: visible;
}
</style>
