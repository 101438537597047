<template>
    <div style="width: 100%;">
        <common-title-panel-vue title="에너지 비용 분석 결과"></common-title-panel-vue>
        <div class="row m-0">
            <!-- 용도별 비용 테이블 -->
            <div class="col-6 pl-0">
                <div class="table-responsive">
                    <table class="table table-bordered text-center">
                        <colgroup>
                            <col style="width: 15%;" />
                            <col style="width: 30%;" />
                            <col style="width: 25%;" />
                            <col style="width: 30%;" />
                        </colgroup>
                        <thead class="thead-dark">
                            <tr>
                                <th colspan="4">{{ "용도별 비용 (천원)" }}</th>
                            </tr>
                            <tr>
                                <th>{{ "구분" }}</th>
                                <th>{{ "전월 (증감)" }}</th>
                                <th>{{ "당월" }}</th>
                                <th>{{ "전년 동월 (증감)" }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="item in formattedCosts.usageCosts"
                                :key="`usage-${item.category}`"
                                style="font-weight: bold;"
                            >
                                <td>{{ item.category }}</td>
                                <td>
                                    {{ item.prevMonth }}&nbsp;
                                    <span
                                        :class="
                                            Number(item.prevDiff) > 0
                                                ? 'text-blue'
                                                : Number(item.prevDiff) < 0
                                                ? 'text-danger'
                                                : ''
                                        "
                                    >
                                        {{ `(${Number(item.prevDiff) > 0 ? "+" : ""}${item.prevDiff})` }}
                                    </span>
                                </td>
                                <td>{{ item.currentMonth }}</td>
                                <td>
                                    {{ item.lastYearSameMonth }}&nbsp;
                                    <span
                                        :class="
                                            Number(item.lastYearDiff) > 0
                                                ? 'text-blue'
                                                : Number(item.lastYearDiff) < 0
                                                ? 'text-danger'
                                                : ''
                                        "
                                    >
                                        {{ `(${Number(item.lastYearDiff) > 0 ? "+" : ""}${item.lastYearDiff})` }}
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <!-- 에너지원별 비용 테이블 -->
            <div class="col-6 pr-0">
                <div class="table-responsive">
                    <table class="table table-bordered text-center">
                        <colgroup>
                            <col style="width: 15%;" />
                            <col style="width: 30%;" />
                            <col style="width: 25%;" />
                            <col style="width: 30%;" />
                        </colgroup>
                        <thead class="thead-dark">
                            <tr>
                                <th colspan="4">{{ "에너지원별 비용 결과 (천원)" }}</th>
                            </tr>
                            <tr>
                                <th>{{ "구분" }}</th>
                                <th>{{ "전월 (증감)" }}</th>
                                <th>{{ "당월" }}</th>
                                <th>{{ "전년 동월 (증감)" }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="item in formattedCosts.energyCosts"
                                :key="`energy-${item.category}`"
                                style="font-weight: bold;"
                            >
                                <td>{{ item.category }}</td>
                                <td>
                                    {{ item.prevMonth }}&nbsp;
                                    <span
                                        :class="
                                            Number(item.prevDiff) > 0
                                                ? 'text-blue'
                                                : Number(item.prevDiff) < 0
                                                ? 'text-danger'
                                                : ''
                                        "
                                    >
                                        {{ `(${Number(item.prevDiff) > 0 ? "+" : ""}${item.prevDiff})` }}
                                    </span>
                                </td>
                                <td>{{ item.currentMonth }}</td>
                                <td>
                                    {{ item.lastYearSameMonth }}&nbsp;
                                    <span
                                        :class="
                                            Number(item.lastYearSameMonth) > 0
                                                ? 'text-blue'
                                                : Number(item.lastYearSameMonth) < 0
                                                ? 'text-danger'
                                                : ''
                                        "
                                    >
                                        {{
                                            `(${Number(item.lastYearSameMonth) > 0 ? "+" : ""}${
                                                item.lastYearSameMonth
                                            })`
                                        }}
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CommonTitlePanelVue from "../panels/CommonTitlePanel.vue";
export default {
    props: {
        costDableData: {
            type: Object,
            default: () => {},
        },
    },
    components: { CommonTitlePanelVue },
    data() {
        return {};
    },
    computed: {
        formattedCosts() {
            const usageData = this.costDableData.usageData;
            const energySourceData = this.costDableData.energySourceData;

            // 천원 단위 변환 함수
            function _toStr(value) {
                return typeof value === "number"
                    ? (value / 1000).toFixed(0).toLocaleString() // 천원 단위, 소수점 버림
                    : value ?? 0;
            }

            function _calcDiff(current, previous) {
                if (typeof current !== "number" || typeof previous !== "number") return 0;
                const diff = (current - previous) / 1000;
                return diff.toFixed(0).toLocaleString();
            }

            const usageCosts = Object.entries(usageData).map(([category, values]) => {
                const [prevMonth, currentMonth, lastYearSameMonth] = values;
                return {
                    category,
                    prevMonth: _toStr(prevMonth),
                    prevDiff: _calcDiff(currentMonth, prevMonth),
                    currentMonth: _toStr(currentMonth),
                    lastYearSameMonth: _toStr(lastYearSameMonth),
                    lastYearDiff: _calcDiff(currentMonth, lastYearSameMonth),
                };
            });

            const energyCosts = Object.entries(energySourceData).map(([category, values]) => {
                const [prevMonth, currentMonth, lastYearSameMonth] = values;
                return {
                    category,
                    prevMonth: _toStr(prevMonth),
                    prevDiff: _calcDiff(currentMonth, prevMonth),
                    currentMonth: _toStr(currentMonth),
                    lastYearSameMonth: _toStr(lastYearSameMonth),
                    lastYearDiff: _calcDiff(currentMonth, lastYearSameMonth),
                };
            });

            return {
                usageCosts,
                energyCosts,
            };
        },
    },
    watch: {},
    mounted() {},
    created() {},
    methods: {},
};
</script>

<style scoped>
th,
td {
    vertical-align: middle !important;
    font-size: 0.75rem;
    padding: 5px;
}
thead th {
    background-color: #003366 !important;
    color: #fff;
}
</style>
