<template>
    <div style="width: 100%;">
        <common-title-panel :title="title" :subTitle="subTitle"></common-title-panel>
        <div ref="chart" :style="{ width: '100%', height: chartHeight + 'vh' }"></div>
    </div>
</template>

<script>
/**
 * data, title 등을 props 로 받아 전월, 당월, 전년 동월을 표현하는 부분에 공통 stack 차트로 사용
 * TODO: 현재 title 은 props 로 받고 있고 data, option 등을 propos 로 받는 작업 해야함.
 */
import * as echarts from "echarts";
import CommonTitlePanel from "../panels/CommonTitlePanel.vue";
import analyzeConfig from "../analyzeConfig.json";

export default {
    props: {
        title: {
            type: String,
            default: "",
        },
        subTitle: {
            type: String,
            default: "",
        },
        chartHeight: {
            type: Number,
            default: 300,
        },
        data: {
            type: Object,
            default: () => {},
        },
        color: {
            type: Object,
            default: () => {},
        },
        xAxisLabel: {
            type: Array,
            default: () => [],
        },
        showLabel: {
            type: Boolean,
            default: true,
        },
    },
    components: { CommonTitlePanel },
    data() {
        return {
            chart: null,
            chartData: null,
            analyzeConfig,
        };
    },
    watch: {
        data: {
            async handler(newVal) {
                this.chartData = newVal;
                await this.initChart();
            },
        },
    },
    async mounted() {
        this.chartData = this.data;
        await this.initChart();
        this.$nextTick(() => {
            window.addEventListener("resize", this.resizeChart);
        });
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.resizeChart);
    },
    methods: {
        initChart() {
            this.chart = echarts.init(this.$refs.chart);
            const option = {
                tooltip: {
                    trigger: "axis",
                    axisPointer: { type: "shadow" },
                    formatter: (params) => {
                        let result = `<div style="width: 200px">
                                        <div class="mb-3">
                                            <p class="m-0 p-0">${params[0].axisValue}</p>`;
                        params.forEach((item) => {
                            const translatedName = analyzeConfig.transKorean[item.seriesName] || item.seriesName;
                            result += `<div style="display: flex; justify-content: space-between;">
                                            <div>
                                                ${item.marker}
                                                <span>${translatedName}</span>
                                            </div>
                                            <span style="font-weight: bold;">${item.data ?? "-"}</span>
                                        </div>`;
                        });
                        result += `</div></div>`;
                        return result;
                    },
                },
                legend: {
                    bottom: 0,
                    formatter: (params) => {
                        const translatedName = analyzeConfig.transKorean[params] || params;
                        return translatedName;
                    },
                },
                grid: {
                    // top: 40,
                    top: !this.isEmpty(this.subTitle) ? 20 : 10,
                    left: "8%",
                    right: "3%",
                    bottom: 50,
                },
                xAxis: {
                    type: "category",
                    data: this.xAxisLabel,
                },
                yAxis: [
                    {
                        type: "value",
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: "#e0e0e0", // 그리드선 색상 지정
                                type: "dashed",
                            },
                        },
                    },
                    {
                        type: "value",
                        position: "right",
                        splitLine: { show: false },
                    },
                ],
            };
            // props 로 받은 data 세팅
            if (!this.isEmpty(this.chartData)) {
                option.series = [];
                option.legend.data = Object.keys(this.chartData);
                Object.keys(this.chartData).forEach((key) => {
                    option.series.push({
                        name: key,
                        type: key.includes("total") ? "bar" : "line",
                        data: this.chartData[key],
                        itemStyle: {
                            color: this.color[key] || "#f44336",
                            borderRadius: key.includes("total") ? [10, 10, 0, 0] : undefined,
                        },
                        lineStyle: !key.includes("total")
                            ? {
                                  type: "dashed",
                                  width: 2,
                                  //   dashOffset: 2,
                              }
                            : {},
                        yAxisIndex: key == "outerTemp" || key == "indoorTemp" ? 1 : 0,
                        smooth: !key.includes("total") ? true : undefined,
                    });
                });
            }
            this.chart.setOption(option);
        },
        resizeChart() {
            if (this.chart) {
                this.chart.resize();
            }
        },
    },
};
</script>

<style scoped>
.chart-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
</style>
