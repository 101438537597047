<template>
    <div style="width: 100%;">
        <common-title-panel :title="title"></common-title-panel>
        <div ref="chart" :style="{ width: '100%', height: chartHeight + 'vh' }"></div>
    </div>
</template>

<script>
/**
 * data, title 등을 props 로 받아 전월, 당월, 전년 동월을 표현하는 부분에 공통 stack 차트로 사용
 * TODO: 현재 title 은 props 로 받고 있고 data, option 등을 propos 로 받는 작업 해야함.
 */
import * as echarts from "echarts";
import CommonTitlePanel from "../panels/CommonTitlePanel.vue";
export default {
    props: {
        title: {
            type: String,
            default: "",
        },
        chartHeight: {
            type: Number,
            default: 300,
        },
        data: {
            type: Object,
            default: () => {},
        },
        color: {
            type: Object,
            default: () => {},
        },
        xAxisLabel: {
            type: Array,
            default: () => [],
        },
        showLabel: {
            type: Boolean,
            default: true,
        },
    },
    components: { CommonTitlePanel },
    data() {
        return {
            chart: null,
        };
    },
    watch: {
        data: {
            async handler(newVal) {
                this.chartData = newVal;
                await this.initChart();
            },
        },
    },
    async mounted() {
        this.chartData = this.data;
        await this.initChart();
        this.$nextTick(() => {
            window.addEventListener("resize", this.resizeChart);
        });
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.resizeChart);
    },
    methods: {
        initChart() {
            this.chart = echarts.init(this.$refs.chart);
            const title = this.title;
            const option = {
                tooltip: {
                    trigger: "axis",
                    axisPointer: { type: "shadow" },
                },
                legend: {
                    bottom: 0,
                    itemWidth: 15,
                    itemHeight: 10,
                    textStyle: {
                        fontSize: 12,
                    },
                },
                grid: {
                    top: 10,
                    left: "3%",
                    right: "3%",
                    bottom: 50,
                },
                xAxis: {
                    type: "category",
                    data: this.xAxisLabel,
                },
                yAxis: {
                    type: "value",
                    axisLine: { show: false },
                    axisLabel: { show: false },
                    axisTick: { show: false },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: "#e0e0e0", // 그리드선 색상 지정
                            type: "dashed",
                        },
                    },
                },
            };
            // props 로 받은 data 세팅
            if (!this.isEmpty(this.chartData)) {
                option.series = [];
                option.legend.data = Object.keys(this.chartData);
                Object.keys(this.chartData).forEach((key) => {
                    option.series.push({
                        name: key,
                        type: "bar",
                        data: this.chartData[key].map((item) => {
                            let data = item == 0 ? null : item;
                            return data;
                        }),
                        itemStyle: {
                            color: this.color[key],
                            borderRadius: [10, 10, 0, 0],
                        },
                        label: {
                            show: this.showLabel,
                            position: "inside",
                            fontSize: 12,
                            color: "#303030",
                            textBorderColor: "#fff",
                            textBorderWidth: 1,
                            formatter: (params) => {
                                if (title.includes("비용")) {
                                    const divided = Math.floor(params.data / 1000);
                                    return divided >= 1000 ? divided.toLocaleString() : divided;
                                } else {
                                    return params.data >= 1000 ? params.data.toLocaleString() : params.data;
                                }
                            },
                        },
                    });
                });
            }
            this.chart.setOption(option);
        },
        resizeChart() {
            if (this.chart) {
                this.chart.resize();
            }
        },
    },
};
</script>

<style scoped>
.chart-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
</style>
