<template>
    <div class="container">
        <common-title-panel
            title="건축물에너지효율등급"
            :subTitle="`단위면적당 1차에너지 소요량 (${data.values.grade.unitInfo.unit} · 월)`"
        />
        <div ref="pyramidChart" class="pyramidChart"></div>
    </div>
</template>

<script>
import * as echarts from "echarts";
import CommonTitlePanel from "../panels/CommonTitlePanel.vue";
export default {
    props: ["data"],
    name: "PyramidBarChart",
    components: { CommonTitlePanel },
    data() {
        return {
            chart: null,
            gradeData: [
                { label: "1+++", value: 10 },
                { label: "1++", value: 15 },
                { label: "1+", value: 20 },
                { label: "1", value: 25 },
                { label: "2", value: 30 },
                { label: "3", value: 35 },
                { label: "4", value: 40 },
                { label: "5", value: 45 },
                { label: "6", value: 50 },
                { label: "7", value: 55 },
            ],
        };
    },
    watch: {
        data() {
            this.resizeChart();
        },
    },
    mounted() {
        this.setChartHeight();
        this.renderChart();
    },
    methods: {
        resizeChart() {
            this.chart && this.chart.resize();
        },
        setChartHeight() {
            const barHeight = 32;
            const totalHeight = this.gradeData.length * barHeight;
            this.$refs.pyramidChart.style.height = `${totalHeight}px`;
        },
        renderChart() {
            this.chart = echarts.init(this.$refs.pyramidChart);
            // const that = this;
            const colorGradient = [
                "#4caf50",
                "#7cb342",
                "#cddc39",
                "#ffeb3b",
                "#ffc107",
                "#ff9800",
                "#ff7043",
                "#ff5722",
                "#f44336",
                "#d32f2f",
            ];

            const option = {
                grid: {
                    left: "5%",
                    right: "5%",
                    top: "5%",
                    bottom: "20%",
                    containLabel: true,
                },
                tooltip: {
                    formatter: (params) => `${params.name}: ${params.value}`,
                },
                xAxis: {
                    type: "value",
                    show: false,
                    max: 60,
                },
                yAxis: {
                    type: "category",
                    inverse: true,
                    data: this.gradeData.map((item) => item.label),
                    axisLine: { show: false },
                    axisTick: { show: false },
                    axisLabel: { show: false },
                },
                series: [
                    {
                        type: "bar",
                        tooltip: {
                            show: false,
                        },
                        z: 10,
                        barCategoryGap: "0%",
                        barWidth: "100%",
                        data: this.gradeData.map((item, index) => ({
                            value: item.value,
                            label: item.label,
                            itemStyle: { color: colorGradient[index] },
                        })),
                        label: {
                            show: true,
                            position: "insideLeft",
                            formatter: (params) => `${params.name}`,
                            color: "#fff",
                            fontSize: 8,
                            fontWeight: "bold",
                        },
                        markLine: {
                            z: 30,
                            zlevel: 1,
                            tooltip: {
                                show: false,
                            },
                            emphasis: {
                                disabled: true,
                            },
                            lineStyle: {
                                type: "solid",
                                color: "#388E3C",
                            },
                            data: [
                                [
                                    {
                                        xAxis: 0,
                                        yAxis: this.data.values.grade.measuredGrade,
                                        lineStyle: {
                                            color: "#388E3C",
                                            width: 1,
                                            type: "solid",
                                        },
                                    },
                                    {
                                        xAxis: 60,
                                        yAxis: this.data.values.grade.measuredGrade,
                                        lineStyle: {
                                            color: "#388E3C",
                                            width: 1,
                                            type: "solid",
                                        },
                                    },
                                ],
                                [
                                    {
                                        xAxis: 0,
                                        yAxis: this.data.values.grade.certifiedGrade,
                                        lineStyle: {
                                            color: "#1976D2",
                                            width: 1,
                                            type: "solid",
                                        },
                                    },
                                    {
                                        xAxis: 60,
                                        yAxis: this.data.values.grade.certifiedGrade,
                                        lineStyle: {
                                            color: "#1976D2",
                                            width: 1,
                                            type: "solid",
                                        },
                                    },
                                ],
                            ],
                            label: {
                                show: false,
                            },
                            symbol: ["none", "none"],
                            symbolSize: 10,
                        },
                        markPoint: {
                            tooltip: {
                                show: false,
                            },
                            emphasis: {
                                disabled: true,
                            },
                            data: [
                                {
                                    xAxis: 55,
                                    yAxis: this.data.values.grade.measuredGrade,
                                    value: "측정등급",
                                    symbol: "path://M10,0 L90,0 L90,20 L10,20 L0,10 Z",
                                    symbolSize: [55, 20],
                                    label: {
                                        show: true,
                                        position: "inside",
                                        formatter: "측정등급",

                                        color: "#fff",
                                        fontSize: 10,
                                        fontWeight: "bold",
                                    },
                                    itemStyle: {
                                        color: "#388E3C",
                                    },
                                },

                                {
                                    xAxis: 55,
                                    yAxis: this.data.values.grade.certifiedGrade,
                                    value: "인증등급",
                                    symbol: "path://M10,0 L90,0 L90,20 L10,20 L0,10 Z",
                                    symbolSize: [55, 20],
                                    label: {
                                        show: true,
                                        position: "inside",
                                        formatter: "인증등급",
                                        color: "#fff",
                                        fontSize: 10,
                                        fontWeight: "bold",
                                    },
                                    itemStyle: {
                                        color: "#1976D2",
                                    },
                                },
                            ],
                        },
                    },
                ],
            };

            this.chart.setOption(option);
            window.addEventListener("resize", () => this.chart.resize());
        },
    },
};
</script>

<style scoped>
.container {
    width: 100%;
}
.pyramidChart {
    width: 100%;
}
</style>
