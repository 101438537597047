<template>
    <div class="gauge-chart-container">
        <div class="gauge-chart" ref="sumDoughnutChart" />
    </div>
</template>

<script>
import * as echarts from "echarts";
export default {
    props: ["data"],
    mounted() {
        this.renderChart();
        window.addEventListener("resize", this.resizeChart);
    },
    watch: {
        data: {
            handler() {
                this.renderChart();
            },
            deep: true,
        },
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.resizeChart);
        if (this.chart) {
            this.chart.dispose();
        }
    },
    methods: {
        resizeChart() {
            if (this.chart) {
                this.chart.resize();
            }
        },
        renderChart() {
            if (!this.chart) {
                this.chart = echarts.init(this.$refs.sumDoughnutChart);
            }
            const option = this.data || {
                series: [
                    {
                        type: "gauge",
                        startAngle: 180,
                        endAngle: 0,
                        center: ["50%", "60%"],
                        radius: "95%",
                        min: 0,
                        max: 100,
                        splitNumber: 10,
                        axisLine: {
                            lineStyle: {
                                width: 8,
                                color: [[1, "#66CCFF"]],
                            },
                        },
                        pointer: {
                            icon: "path://M12.8,0.7l12,40.1H0.7L12.8,0.7z",
                            length: "30%",
                            width: 12,
                            offsetCenter: [0, "-60%"],
                            itemStyle: {
                                color: "#FF0000",
                            },
                        },
                        axisTick: {
                            length: 4,
                            distance: 1,
                            lineStyle: {
                                color: "#90A4AE",
                                width: 1,
                            },
                        },
                        splitLine: {
                            length: 8,
                            distance: 1,
                            lineStyle: {
                                color: "#90A4AE",
                                width: 2,
                            },
                        },
                        axisLabel: {
                            color: "#464646",
                            fontSize: 10,
                            distance: -20,
                            rotate: "tangential",
                        },
                        title: {
                            offsetCenter: [0, "-10%"],
                            fontSize: 14,
                        },
                        detail: {
                            fontSize: 16,
                            offsetCenter: [0, "-30%"],
                            valueAnimation: true,
                            color: "black",
                            formatter: "{value} km/h",
                        },
                        data: [{ value: 50, name: "Speed" }],
                    },
                ],
            };
            this.chart.setOption(option);
        },
    },
};
</script>

<style scoped>
/* 부모 컨테이너에 명시적 높이를 부여해야 합니다.
     예를 들어, 상위 컴포넌트에서 이 컴포넌트의 높이를 설정하거나,
     body, html에 height: 100%를 지정하는 방식 등이 있습니다. */
.gauge-chart-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.gauge-chart {
    width: 100%;
    height: 100%;
}
</style>
