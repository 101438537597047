<template>
    <div class="overviewContainer">
        <common-title-panel title="실내 환경 운영 개요" />

        <!-- DI 카드 -->
        <div class="overviewCard" :style="{ backgroundColor: getDiColor(data.chartData.DI.currentMonth.AVG) }">
            <div class="overviewCardFirstRow">
                <div class="overviewCardfirstRowTitle">
                    <div class="firstRowTitle">
                        불쾌지수
                        <div class="iconBox" v-b-tooltip.hover :title="DItext">
                            <i class="fas fa-exclamation icon"></i>
                        </div>
                    </div>
                    <div class="firstRowSubTitle">
                        Discomfort Index
                    </div>
                </div>
                <div class="overviewCardFirstRowContent">
                    <div class="overviewFirstRowValue">{{ data.chartData.DI.currentMonth.AVG || "-" }}</div>
                    <div class="overviewExpression">
                        <SmileyFace
                            :mood="calcGradeDi(data.chartData.DI.currentMonth.AVG)"
                            v-if="data.chartData.DI.currentMonth.AVG"
                        />
                    </div>
                </div>
            </div>
            <div class="overviewCardSecondRow">
                <div class="prevMonth">
                    <div class="timeLabel">전월</div>
                    <div class="cardValue">{{ data.chartData.DI.prevMonth.AVG || "-" }}</div>
                </div>
                <div class="sameMonthLastYear">
                    <div class="timeLabel">전년 동월</div>
                    <div class="cardValue">{{ data.chartData.DI.prevYearMonth.AVG || "-" }}</div>
                </div>
            </div>
        </div>

        <div class="blank"></div>

        <!-- Indoor Air Quality 카드 -->
        <div class="overviewCard" :style="{ backgroundColor: getIAQColor(data.chartData.CO2.currentMonth.AVG) }">
            <div class="overviewCardFirstRow">
                <div class="overviewCardfirstRowTitle">
                    <div class="firstRowTitle">
                        실내 환경지수
                        <div class="iconBox" v-b-tooltip.hover :title="CO2text">
                            <i class="fas fa-exclamation icon"></i>
                        </div>
                    </div>
                    <div class="firstRowSubTitle">
                        Indoor Air Quality
                    </div>
                </div>
                <div class="overviewCardFirstRowContent">
                    <div class="overviewFirstRowValue">{{ data.chartData.CO2.currentMonth.AVG || "-" }}</div>
                    <div class="overviewExpression">
                        <SmileyFace
                            :mood="calcGradeIAQ(data.chartData.CO2.currentMonth.AVG)"
                            v-if="data.chartData.CO2.currentMonth.AVG"
                        />
                    </div>
                </div>
            </div>
            <div class="overviewCardSecondRow">
                <div class="prevMonth">
                    <div class="timeLabel">전월</div>
                    <div class="cardValue">{{ data.chartData.CO2.prevMonth.AVG || "-" }}</div>
                </div>
                <div class="sameMonthLastYear">
                    <div class="timeLabel">전년 동월</div>
                    <div class="cardValue">{{ data.chartData.CO2.prevYearMonth.AVG || "-" }}</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import CommonTitlePanel from "./CommonTitlePanel.vue";
import SmileyFace from "./HumanExpressionPanel.vue";

export default {
    components: {
        SmileyFace,
        CommonTitlePanel,
    },
    props: ["data"],
    data() {
        return {
            smile: "#448AFF",
            sad: "#FFA000",
            angry: "#FF5252",
            DItext: "DI 지수는 29 이하일 시 쾌적, 30과 39 사이일 시 불편, 40 이상일 때 주의를 나타내는 지수입니다.",
            CO2text:
                "실내 환경지수는 실내 CO2 지수 기반으로서 700 이하일 시 쾌적, 700 ~ 1000일시 불편, 1000 이상일 시 주의를 나타냅니다.",
        };
    },
    methods: {
        calcGradeDi(val) {
            if (val <= 29) {
                return "smile";
            } else if (val >= 30 && val <= 39) {
                return "sad";
            } else if (val >= 40) {
                return "angry";
            }
        },
        calcGradeIAQ(val) {
            if (val <= 700) {
                return "smile";
            } else if (val >= 700 && val <= 1000) {
                return "sad";
            } else if (val >= 1000) {
                return "angry";
            }
        },
        // calcGrade 결과에 따른 DI 카드 색상 반환
        getDiColor(val) {
            const mood = this.calcGradeDi(val);
            return this[mood] || this.smile; // 기본 색상 처리
        },
        // calcGrade 결과에 따른 IAQ 카드 색상 반환
        getIAQColor(val) {
            const mood = this.calcGradeIAQ(val);
            return this[mood] || this.smile;
        },
    },
};
</script>

<style scoped>
.overviewContainer {
    width: 100%;
    height: 100%;
    padding: 5px;
}
.overviewTitle {
    width: 100%;
    height: 10%;
    font-size: 18px;
    font-weight: 800;
    color: #4d4d4d;
    display: flex;
    justify-content: center;
}
.blank {
    width: 100%;
    height: 3%;
}
.overviewCard {
    width: 100%;
    height: 40%;
    background-color: #007bff;
    border-radius: 8px;
    padding: 10px 15px 10px 15px;
}
.overviewCardFirstRow {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: space-between;
}
.overviewCardfirstRowTitle {
    width: 50%;
}
.overviewCardFirstRowContent {
    width: 50%;
    height: 50%;
    display: flex;
    justify-content: end;
}
.overviewCardSecondRow {
    width: 100%;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.prevMonth {
    width: 45%;
    display: flex;
    height: 70%;
}
.timeLabel {
    width: 35%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 700;
}
.cardValue {
    width: 60%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 15px;
    font-weight: 700;
}
.sameMonthLastYear {
    width: 47%;
    height: 70%;
    display: flex;
    justify-content: space-between;
}
.firstRowTitle {
    font-size: 20px;
    font-weight: 800;
    color: yellow;
    display: flex;
}
.firstRowSubTitle {
    color: yellow;
}
.overviewFirstRowValue {
    font-size: 25px;
    font-weight: 800;
    color: yellow;
    margin-right: 17px;
}
.icon {
    border: 1px solid white;
    color: white;
    border-radius: 50%;
    font-size: 10px;
    display: inline-block;
    width: 18px;
    height: 18px;
    text-align: center;
    line-height: 16px;
    margin-left: 6px;
}
.iconBox {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
